import { BsEye } from 'react-icons/bs';
import { CalendarioCarritosModalAgregar } from './CalendarioCarritosModalAgregar';
import { CalendarioCarritosModalDetails } from './CalendarioCarritosModalDetails';
import { daysOfWeekDesktop, daysOfWeekMobile } from '../../../app-config';
import { typeCartOrganization, typePunto, typeTurno } from '../../../models';
import { useGetIsMobile } from '../../commons/custom-hooks';
import { useState, FC, Dispatch, SetStateAction, useMemo } from 'react';

type propsType = {
    cartOrg: typeCartOrganization;
    monthName: string;
    punto: typePunto;
    selectedMonth: string;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CalendarioCarritosCalendario: FC<propsType> = ({
    cartOrg, monthName, punto, selectedMonth, setRefreshCounter
}) => {
    const [newTurno, setNewTurno] = useState<typeTurno | null>(null);
    const [selectedTurno, setSelectedTurno] = useState<typeTurno | null>(null);
    const isMobile = useGetIsMobile();

    const auxSelectedMonth: string = useMemo(() =>
        selectedMonth.split('-').reverse().join('-')
    , [selectedMonth]);

    const handleEmptyCellClick = (punto: string, dia: string, horario: string, month: string) => {
        if (monthName) return;  // read only for board section
        setNewTurno({
            hermanos: [],
            horario: { dia, horario },
            id: Date.now().toString(),
            mes: month,
            punto
        });
    }

    return (
        <div className="calendario mb-5 border rounded shadow-sm">
            {selectedTurno &&
                <CalendarioCarritosModalDetails
                    closeModal={() => setSelectedTurno(null)}
                    selectedTurno={selectedTurno}
                />
            }
            {newTurno &&
                <CalendarioCarritosModalAgregar
                    cartOrg={cartOrg}
                    closeModal={() => setNewTurno(null)}
                    newTurno={newTurno}
                    setRefreshCounter={setRefreshCounter}
                />
            }
            <h3 className="text-center mb-3 mt-2">
                {punto.name} {monthName ? `- ${monthName}` : ''}
            </h3>
            <div className="calendario-header d-flex border-bottom mb-1">
                <div className="calendario-header-time flex-fill p-1"></div>
                {(isMobile ? daysOfWeekMobile : daysOfWeekDesktop).map(day =>
                    <div key={day} className="calendario-header-day flex-fill text-center fw-bold border-start">
                        {day}
                    </div>
                )}
            </div>
            <div className="calendario-body">
                {cartOrg.horarios?.map(horario =>
                    <div key={horario.start} className="calendario-row d-flex align-items-start border-bottom py-1">
                        <div className="calendario-time flex-fill text-center fw-bold border-end">
                            <span>{horario.start}</span>
                            <br />
                            <span>{horario.end}</span>
                        </div>
                        {daysOfWeekDesktop.map(day => {
                            const turnoFiltrado = cartOrg.turnos.find(turno =>
                                turno.punto === punto.name &&
                                turno.horario.dia === day &&
                                turno.horario.horario === horario.start + '-' + horario.end &&
                                turno.mes === auxSelectedMonth
                            );
                            return (
                                <div key={day + horario.start}
                                    className="calendario-cell flex-fill text-center border-start pointer custom-card"
                                    onClick={turnoFiltrado
                                        ? () => setSelectedTurno(turnoFiltrado)
                                        : () => handleEmptyCellClick(punto.name, day, `${horario.start}-${horario.end}`, auxSelectedMonth)
                                    }
                                >
                                    {turnoFiltrado ?
                                        (isMobile ?
                                            <div key={turnoFiltrado.id}
                                                className="calendario-turno rounded p-2"
                                            >
                                                <BsEye />
                                            </div>
                                            :
                                            <div key={turnoFiltrado.id}
                                                className="calendario-turno rounded"
                                            >
                                                {turnoFiltrado.hermanos?.map((hermano, i) =>
                                                    <div key={`hermano-${i}`}>
                                                        {hermano.name} {hermano.lastName}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                        :
                                        <div className="muted" style={{ height: '120px' }}></div>
                                    }
                                </div>
                            );
                        })}
                    </div>
                )}
                <div>
                    <div className="text-center mt-1 fw-bold">
                        <p className={'mb-1'}>
                            Punto de retiro: {punto.pickupCartPoint}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
