import { typeBoardItem, typeCartOrganization, typeHTHTerritory, typeLogsPackage, typeTelephonicTerritory, typeUser } from '.';

export type typeConfig = {
    congregation: number;
    date: number;
    dbBackupLastDate: string;
    isDisabledCloseHthFaces: boolean;
    isDisabledEditHthMaps: boolean;
    isDisabledHthFaceObservations: boolean;
    name: string;
    numberOfTerritories: number;
    usingLettersForBlocks: boolean;
}

export type typeDbBackup = {
    boardItems: typeBoardItem[];
    cartOrg: typeCartOrganization;
    config: typeConfig;
    houseToHouseTerritories: typeHTHTerritory[];
    logs: typeLogsPackage[];
    telephonicTerritories: typeTelephonicTerritory[];
    users: typeUser[];
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export const unauthenticatedConfig: typeConfig = {
    congregation: 0,
    date: 0,
    dbBackupLastDate: '',
    isDisabledCloseHthFaces: true,
    isDisabledEditHthMaps: true,
    isDisabledHthFaceObservations: true,
    name: "",
    numberOfTerritories: 0,
    usingLettersForBlocks: false
}
