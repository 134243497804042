import { appName } from "../../app-config";
import { Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import { FC } from "react";
import { setValuesAndOpenAlertModalReducer } from "../../store";
import { useDispatch } from "react-redux";
import { useGetCurrentUser, useGetIsMobile } from "../commons/custom-hooks";
import { userPhoneNumberRegistrationService } from "../../services";

type propsType = {
    closeModal: () => void;
}

export const UserPersonalData: FC<propsType> = ({ closeModal }) => {
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    const deletePhoneNumber = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Confirmar',
            message: `Se va a eliminar el número de teléfono ${user.phoneNumber} de la aplicación y ya no se podrán recibir en WhatsApp notificaciones, recordatorios ni opciones de autogestión`,
            mode: 'confirm',
            animation: 3,
            execution: async () => {
                const response = await userPhoneNumberRegistrationService('delete', user.id, user.email, null, null);
                console.log(response);
                window.location.reload();
            }
        }));
    }

    return (
        <Modal
            centered
            fullscreen={'md-down'}
            onHide={closeModal}
            show={true}
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Modal.Title> Datos personales en {appName} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{ maxWidth: '500px', padding: isMobile ? '35px 30px 0' : '35px 0' }}>
                    <FloatingLabel
                        className={'mb-3 text-dark'}
                        label={"Dirección de Correo Electrónico"}
                    >
                        <Form.Control
                            type={'email'}
                            className={'form-control'}
                            placeholder={''}
                            value={user.email}
                            disabled={true}
                        />
                    </FloatingLabel>
                    {!!user.phoneNumber &&
                        <>
                        <FloatingLabel
                            className={'mb-3 text-dark'}
                            label={`Teléfono celular ${!!user.phoneNumberRequestTimestamp ? '... Pendiente confirmación' : ''}`}
                        >
                            <Form.Control
                                type={'text'}
                                className={'form-control'}
                                placeholder={''}
                                value={user.phoneNumber}
                                disabled={true}
                            />
                        </FloatingLabel>
                        <button
                            className={'btn btn-general-red py-1 mt-1'}
                            onClick={deletePhoneNumber}
                            style={{ width: '350px' }}
                        >
                            Eliminar mi número de teléfono
                        </button>
                        </>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
