import { addHermanoService, updateSolicitudService, updateHermanoService } from '../../../services/cartServices';
import { DisponibilidadCarritosForm } from '../carritos-common/DisponibilidadCarritosForm';
import { goToTop } from '../../../services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { prioridades, typeCartOrganization, typeDisponibilidad, typeHermano, typePrioridad, typeSolicitud, typeUser } from '../../../models';
import { useDispatch } from 'react-redux';
import { useState, useEffect, FC, Dispatch, SetStateAction, FormEvent } from 'react';
import Select from 'react-select';

type propsType = {
    cartOrg: typeCartOrganization;
    hermanoToEdit?: typeHermano;
    onSave: () => void;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
    solicitud?: typeSolicitud;
}

export const HermanosCarritosFormAgregar: FC<propsType> = ({
    cartOrg, onSave, setRefreshCounter, hermanoToEdit, solicitud
}) => {
    const [disponibilidad, setDisponibilidad] = useState<typeDisponibilidad[]>([]);
    const [lastName, setLastName] = useState('');
    const [name, setName] = useState('');
    const [participo, setParticipo] = useState(false);
    const [phone, setPhone] = useState('');
    const [selectedPrioridad, setSelectedPrioridad] = useState(prioridades[0].valor);
    const [selectedUser, setSelectedUser] = useState<typeHermano | null>(null);
    const dispatch = useDispatch();

    const setSolicitudCompleted = async () => {
        if (!solicitud) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a completar la solicitud de ${solicitud.name} ${solicitud.lastName}`,
            execution: async () => {
                solicitud.status = 'completed';
                dispatch(showLoadingModalReducer());
                const success = await updateSolicitudService(solicitud);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo editar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    const setValuesToEdit = (hermanoEdit: typeHermano) => {
        setSelectedUser(hermanoEdit);
        setName(hermanoEdit.name);
        setLastName(hermanoEdit.lastName);
        setPhone(hermanoEdit.phone);
        setParticipo(hermanoEdit.participo);
        setSelectedPrioridad(hermanoEdit.prioridad);
        setDisponibilidad(hermanoEdit.disponibilidad);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (hermanoToEdit && hermanoToEdit.user.id) {
            const updatedHermano: typeHermano = {
                name,
                lastName,
                phone,
                participo,
                disponibilidad,
                prioridad: selectedPrioridad,
                user: hermanoToEdit.user,
                id: hermanoToEdit.id
            };
            handleUpdate(updatedHermano);
        } else if (hermanoToEdit && !hermanoToEdit.user.id) {
            setSolicitudCompleted();
        } else {
            handleCreate();
        }
    }

    const handleUpdate = async (hermanoUpdate: typeHermano) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se van a modificar los datos de ${hermanoUpdate.name} ${hermanoUpdate.lastName}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await updateHermanoService(hermanoUpdate);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    onSave();
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo editar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    const handleCreate = async () => {
        if (!selectedUser) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a suscribir a un hermano: ${name} ${lastName}`,
            execution: async () => {
                const newHermano: typeHermano = {
                    disponibilidad: disponibilidad,
                    lastName,
                    name,
                    participo,
                    phone,
                    prioridad: selectedPrioridad,
                    user: selectedUser.user
                }
                dispatch(showLoadingModalReducer());
                const success = await addHermanoService(newHermano);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    onSave();
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo agregar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    useEffect(() => {
        goToTop();
        if (hermanoToEdit) {
            setValuesToEdit(hermanoToEdit);
        }
    }, []);

    return (
        <div className="container p-2">
            <form onSubmit={handleSubmit}>
                <div className="mb-3 row ">
                    <div className="col-md-6 col-sm-12 m-auto mb-2">
                        <label className="form-label">Seleccionar Usuario</label>
                        <Select
                            options={cartOrg.users?.sort((u1, u2) => u1.email.localeCompare(u2.email)) as unknown as typeHermano[] ?? []}
                            getOptionLabel={user => `${user?.email}`}
                            getOptionValue={user => user?.user?.id.toString() ?? ''}
                            value={selectedUser}
                            onChange={user => setSelectedUser(user)}
                            required
                            autoFocus={!hermanoToEdit}
                            isDisabled={!!hermanoToEdit}
                        />
                    </div>
                </div>
                <div className="mb-3 row">
                    <div className="col-md-6 col-sm-12">
                        <label className="form-label">Nombre</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingrese nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label className="form-label">Apellido</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingrese apellido"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                </div>
                <div className="mt-4 row">
                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Teléfono</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingrese numero de celular"
                            value={phone}
                            // value={selectedUser ? selectedUser.phoneNumber ?? 'No registró' : '-'}
                            onChange={(e) => setPhone(e.target.value)}
                            // disabled
                        />
                    </div>

                    <div className="mb-3 col-md-6 col-sm-12">
                        <label className="form-label">Prioridad</label>
                        <select
                            name="prioridad"
                            className="form-select"
                            value={selectedPrioridad}
                            onChange={e => setSelectedPrioridad(e.target.value as typePrioridad)}
                            required
                        >
                            {prioridades.map(p =>
                                <option key={p.valor} value={p.valor}>{p.etiqueta}</option>
                            )}
                        </select>
                    </div>
                    <div className="bg-light form-check col-md-6 col-sm-12 d-flex align-items-center justify-content-center w-100 gap-4 py-1 mt-3 mb-2"
                        onClick={() => setParticipo(x => !x)}
                    >
                        <input type="checkbox"
                            className="form-check-input"
                            style={{ width: '25px', height: '25px' }}
                            checked={participo}
                            onChange={() => {}}
                        />
                        <label className="form-check-label fw-bold fs-5">
                            ¿Ya participó alguna vez?
                        </label>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <DisponibilidadCarritosForm
                        cartOrg={cartOrg}
                        disponibilidad={disponibilidad}
                        setDisponibilidad={setDisponibilidad}
                    />
                </div>
                <div className="d-flex justify-content-center">
                    <button type="submit"
                        className="btn btn-general-blue mt-3 mb-3 btn-block btn-lg"
                        style={{ width: '50%' }}
                        disabled={!name || !lastName || !phone || !disponibilidad}
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </div>
    )
}
