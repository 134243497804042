import { BsClock, BsMap, BsPen } from 'react-icons/bs';
import { CartPointCrud } from './CartPointCrud';
import { CartPointEnabledPeriod } from './CartPointEnabledPeriod';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { goToTop } from '../../../services';
import { typeNewCartOrganization, typeNewPoint } from '../../../models';

type propsType = {
    cartOrg: typeNewCartOrganization;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartPoint: FC<propsType> = ({ cartOrg, setRefreshCounter }) => {
    const [pointToEdit, setPointToEdit] = useState<typeNewPoint | null>(null);
    const [showForm, setShowForm] = useState(false);
    const [showPeriodsFromPointId, setShowPeriodsFromPointId] = useState('');

    useEffect(() => { goToTop(); }, [pointToEdit, showForm, showPeriodsFromPointId]);

    return (
        <div className={'container mt-3'} style={{ maxWidth: '850px' }}>
            <div className={'mb-3'}>
                <h3 className={'text-center flex-grow-1 mt-2'}>
                    {!showForm ? 'PUNTOS' : pointToEdit ? 'EDITAR PUNTO' : 'AGREGAR PUNTO'} <BsMap />
                </h3>
            </div>

            {!showForm &&
                <>
                <div className={'row'}>
                    {cartOrg.points?.filter(p => !p.wasDeleted && (!showPeriodsFromPointId || showPeriodsFromPointId === p.id)).map(point =>
                        <div key={point.id} className={'col-12 mb-4'}>
                            <div className={'card'}>
                                <div className={'card-body'}>
                                    <h5 className={'card-title'}>
                                        {point.name}
                                    </h5>
                                    <p className={'card-text mb-2'}>
                                        Dirección: {point.address}
                                    </p>
                                    <p className={'card-text'}>
                                        Punto de retiro: {point.pickupCartPoint}
                                    </p>
                                </div>
                                {showPeriodsFromPointId === point.id &&
                                    <CartPointEnabledPeriod
                                        goBack={() => setShowPeriodsFromPointId('')}
                                        point={point}
                                        setRefreshCounter={setRefreshCounter}
                                    />
                                }
                                {!showPeriodsFromPointId &&
                                    <div className={'card-footer d-flex gap-2 justify-content-end'}>
                                        <button className={'btn btn-general-blue btn-sm'}
                                            onClick={() => setShowPeriodsFromPointId(x => x ? '' : point.id)}
                                        >
                                            <BsClock className={'me-2'} />
                                            Horarios Habilitados
                                        </button>
                                        <button className={'btn btn-general-red btn-sm'}
                                            onClick={() => {
                                                setPointToEdit(point);
                                                setShowForm(true);
                                            }}
                                        >
                                            Editar <BsPen />
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </div>
                {!showPeriodsFromPointId &&
                    <div className={'text-center mb-5'}>
                        <button className={`btn btn-general-${showForm ? 'red' : 'blue'}`}
                            onClick={() => {
                                setShowForm(true);
                                setShowPeriodsFromPointId('');
                                setPointToEdit(null);
                            }}
                        >
                            Agregar punto
                        </button>
                    </div>
                }
                </>
            }

            {showForm &&
                <CartPointCrud
                    pointToEdit={pointToEdit}
                    setPointToEdit={setPointToEdit}
                    setRefreshCounter={setRefreshCounter}
                    setShowForm={setShowForm}
                />
            }
        </div>
    )
}
