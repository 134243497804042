import { BsArrowBarDown } from 'react-icons/bs'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { getUsersService } from '../../services'
import { H2, Loading, WarningToaster } from '../commons'
import { io, Socket } from 'socket.io-client'
import { SERVER, socketIoEvents } from '../../app-config'
import { setValuesAndOpenAlertModalReducer } from '../../store'
import { typeUser } from '../../models'
import { useDispatch } from 'react-redux'
import { useGetCurrentUser, useGetIsMobile } from '../commons/custom-hooks'
import { UsersCard, UsersNewUser, UsersRolesModal, UsersSelector } from './users-subcomp'

const socket: Socket = io(SERVER, { withCredentials: true })

type propsType = {
    setIsLoading: Dispatch<SetStateAction<boolean>>
}

export const Users: FC<propsType> = ({ setIsLoading }) => {
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();
    const [emailSearchInputText, setEmailSearchInputText] = useState("")
    const [showNewUser, setShowNewUser] = useState(false)
    const [showRolesModal, setShowRolesModal] = useState(false)
    const [users, setUsers] = useState<typeUser[]|null>(null)
    const [usersToShow, setUsersToShow] = useState<typeUser[]|null>(null)
    const dispatch = useDispatch()
    
    useEffect(() => {
        getUsersService().then((users0: typeUser[]|null) => {
            if (!users0) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode:'alert',
                    title: "Problemas",
                    message: "Algo falló al cargar los usuarios; refrescar",
                    animation: 2
                }))
                return
            }
            setUsers(users0)
        })
    }, [dispatch])

    useEffect(() => {
        socket.on(socketIoEvents.userChange, (congregation: number) => {
            if (congregation !== user.congregation) return
            setIsLoading(true)
            getUsersService().then(users => {
                setIsLoading(false)
                if (users) setUsers(users)
            })
        })
        return () => { socket.off(socketIoEvents.userChange) }
    }, [setIsLoading, user.congregation])

    useEffect(() => {
        (!users?.length || !emailSearchInputText) ?
            setUsersToShow(users)
            :
            setUsersToShow(users.filter(x => x.email.toLowerCase().includes(emailSearchInputText)))
    }, [emailSearchInputText, users])

    // useEffect(() => {
    //     (!users?.length || (!emailSearchInputText && !selectedGroup)) ?
    //             setUsersToShow(users)
    //         :
    //             emailSearchInputText ?
    //                 selectedGroup ?
    //                     setUsersToShow(users.filter(x => x.group === selectedGroup && x.email.toLowerCase().includes(emailSearchInputText)))
    //                 :
    //                     setUsersToShow(users.filter(x => x.email.toLowerCase().includes(emailSearchInputText)))
    //             :
    //                 setUsersToShow(users.filter(x => x.group === selectedGroup))
    // }, [emailSearchInputText, selectedGroup, users])

    return (
    <>
        <H2 title={"USUARIOS"} />
    
        {!users && <Loading mt={'50px'} />}

        {users && (!socket || !socket.connected) &&
            <div style={{ marginTop: '30px', position: 'fixed', zIndex: 4 }}>
                <WarningToaster
                    bodyText={"Refrescar la página y verificar que hay internet"}
                    headerText={<strong> Hay un problema de conexión </strong>}
                />
            </div>
        }

        {user.isAdmin && <>
            <div className={`${isMobile ? '' : 'd-flex justify-content-center'} mt-5 mb-3`}>
                <button className={`btn btn-general-blue btn-size12 ${isMobile ? 'd-block mx-auto mb-1' : 'me-2'}`}
                    style={{ width: '350px', minHeight: '60px' }}
                    onClick={() => setShowRolesModal(true)}
                    disabled={showNewUser}
                >
                    VER ROLES <FaExternalLinkAlt size={isMobile ? '1rem' : '.9rem'} />
                </button>
                <button className={`btn btn-general-blue btn-size12 ${isMobile ? 'd-block mx-auto mt-1' : 'ms-2'}`}
                    style={{ width: '350px', minHeight: '60px' }}
                    onClick={() => setShowNewUser(true)}
                    disabled={showNewUser}
                >
                    NUEVO USUARIO <BsArrowBarDown size={isMobile ? '2rem' : '1.4rem'} />
                </button>
            </div>

            {showRolesModal && users &&
                <UsersRolesModal
                    closeModal={() => setShowRolesModal(false)}
                    users={users}
                />
            }

            {showNewUser &&
                <UsersNewUser
                    setIsLoading={setIsLoading}
                    setShowNewUser={setShowNewUser}
                />
            }
        </>}

        {!!users?.length && !showNewUser &&
            <UsersSelector
                // selectedGroup={selectedGroup}
                setEmailSearchInputText={setEmailSearchInputText}
                // setSelectedGroup={setSelectedGroup}
                usersToShow={usersToShow}
            />
        }

        {!showNewUser && !!usersToShow?.length && usersToShow.map(user =>
            <UsersCard key={user.id}
                currentUser={user}
                setIsLoading={setIsLoading}
            />
        )}
    </>)
}
