import { getHeaders } from '.';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeLogType, typeResponseData } from '../models';

const base: string = pointer.log

export const getAllLogsService = async (all: boolean, type: typeLogType | null, congregation: number | null): Promise<typeResponseData | null> => {
    if (!getTokenFromLSService()) return null;
    try {
        const response = await fetch(`${base}?all=${all}&type=${type ?? ''}&congregation=${congregation ?? ''}`, {
            method: 'GET',
            headers: getHeaders()
        });
        const data: typeResponseData = await response.json();
        if (!data || !data.success || (!data.logs && !data.log)) return null;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
}
