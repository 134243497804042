import { deleteBoardItemFileService } from '../../services';
import { Dispatch, FC, SetStateAction } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../store';
import { typeBoardItem, typeItemBoardFile } from '../../models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode } from '../commons/custom-hooks';

type propsType = {
    item: typeBoardItem;
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardManagerFilesList: FC<propsType> = ({ item, setCounterReload }) => {
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();

    const deleteFile = (file: typeItemBoardFile) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar Eliminar',
            message: `Se van a eliminar el Archivo '${file.originalName}' de '${item.title}'. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteBoardItemFileService(item, file);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setCounterReload(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Hubo un error',
                        message: 'Algo falló y no se pudo eliminar',
                        mode: 'alert',
                        animation: 2
                    }));
                }
            }
        }));
    }

    return (
        <div className={'container mb-4'}>
            <h5>Archivos:</h5>
            {!!item.files.length ?
                <div>
                    <table className={`table table-bordered table-responsive ${isDarkMode ? 'table-dark' : 'table-light'}`}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Fecha</th>
                                <th className={'text-center'}>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.files.map(f =>
                                <tr key={f.bucketName}>
                                    <td>{f.originalName}</td>
                                    <td>{new Date(f.date).toLocaleString()}</td>
                                    <td className={'text-center'}>
                                        <button className={'btn btn-general-red'} onClick={() => deleteFile(f)}>
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                :
                <>No hay</>
            }
        </div>
    )
}
