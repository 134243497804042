import { addPuntoService, updatePuntoService } from '../../../services/cartServices';
import { goToTop } from '../../../services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { typePunto } from '../../../models';
import { useDispatch } from 'react-redux';
import { useState, useEffect, FC, Dispatch, SetStateAction } from 'react';

type propsType = {
    puntoToEdit?: typePunto;
    onSave: () => void;
    onCancel: () => void;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const PuntosCarritosAgregar: FC<propsType> = ({ onCancel, onSave, puntoToEdit, setRefreshCounter }) => {
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [pickupCartPoint, setPickupCartPoint] = useState('');
    const dispatch = useDispatch();

    const handleCreate = async () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a crear un nuevo Punto: '${name}' en ${address}; se guarda en ${pickupCartPoint}`,
            execution: async () => {
                const newPunto: typePunto = { name, address, pickupCartPoint };
                dispatch(showLoadingModalReducer());
                const success = await addPuntoService(newPunto);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    onSave();
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo agregar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    const handleUpdate = async (puntoUpdated: typePunto) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a editar el punto: ${puntoUpdated.name} de ${puntoUpdated.address}; se guarda en ${puntoUpdated.pickupCartPoint}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await updatePuntoService(puntoUpdated);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    onSave();
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo editar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }
    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (puntoToEdit) {
            const updatedPunto: typePunto = { id: puntoToEdit.id, name, address, pickupCartPoint };
            handleUpdate(updatedPunto);
        } else {
            handleCreate();
        }
    }

    useEffect(() => {
        goToTop();
        if (puntoToEdit) {
            console.log(puntoToEdit);
            setName(puntoToEdit.name);
            setAddress(puntoToEdit.address);
            setPickupCartPoint(puntoToEdit.pickupCartPoint);
        } else {
            setName('');
            setAddress('');
            setPickupCartPoint('');
        }
    }, []);

    return (
        <form onSubmit={handleSubmit} className="container mt-3" style={{ maxWidth: '650px' }}>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">
                    Nombre del punto
                </label>
                <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Nombre del punto"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="address" className="form-label">
                    Dirección
                </label>
                <input
                    type="text"
                    id="address"
                    className="form-control"
                    placeholder="Dirección exacta del Punto"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="pickupCartPoint" className="form-label">
                    Punto de retiro
                </label>
                <input
                    type="text"
                    id="pickupCartPoint"
                    className="form-control"
                    placeholder="Direccion de retiro del carrito"
                    value={pickupCartPoint}
                    onChange={(e) => setPickupCartPoint(e.target.value)}
                    required
                />
            </div>
            <div className="d-flex justify-content-center mb-4 mt-4">
                <button disabled={!name || !address || !pickupCartPoint} type="submit" className="btn btn-general-blue">
                    {puntoToEdit ? 'Guardar cambios' : 'Agregar Punto'}
                </button>
                {puntoToEdit &&
                    <button type="button" className="btn btn-secondary ms-2" onClick={onCancel}>
                        Cancelar
                    </button>
                }
            </div>
        </form>
    )
}
