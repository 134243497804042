import { typeUser } from '.';

export type typeSolicitud = {
    comentarios?: string;
    disponibilidad: typeDisponibilidad[];
    id: number;
    lastName: string;
    name: string;
    participo: boolean;
    status: string;
    user: typeUser;
}

export type typeTurno = {
    id: string;
    mes: string;
    punto: string;
    horario: { dia: string; horario: string };
    hermanos: typeHermano[];
}

export type typePrioridad = '1' | '2' | '3';

export type typeHermano = {
    id?: string;
    user: typeUser;
    email?: string;
    name: string;
    lastName: string;
    phone: string;
    participo: boolean;
    prioridad: typePrioridad;
    disponibilidad: typeDisponibilidad[];
}

export type typeCartDetail = typeHermano & typeSolicitud;

export type typeDisponibilidad = {
    punto: string;
    horarios: typeHorarioDisponibilidad[];
}

export type typeHorarioDisponibilidad = {
    dia: string;
    horarios: string[];
}

export type typeHorario = {
    id?: string;
    start: string;
    end: string;
}

export type typePunto = {
    id?: string;
    name: string;
    address: string;
    pickupCartPoint: string;
}

export type typeDisponibilidadForm = {
    punto: string;
    horario: string;
    dia: string;
}

export type typeCartOrganization = {
    congregation: number;
    hermanos: typeHermano[];
    horarios: typeHorario[];
    puntos: typePunto[];
    solicitudes: typeSolicitud[];
    turnos: typeTurno[];
    users?: { email: string; id: number; }[];
}

///////////////////////////////////////////////////////////////////////////////////////////////////

export const prioridades: { etiqueta: string; valor: typePrioridad; }[] = [
    { etiqueta: 'Alta', valor: '1' },
    { etiqueta: 'Media', valor: '2' },
    { etiqueta: 'Baja', valor: '3' }
];
