import { GeoLocationModal, TerritoryNumberBlock } from '.'
import { H2 } from '../commons'
import { ManualLocationModal } from './ManualLocationModal'
import { useGetConfig, useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from '../commons/custom-hooks'
import { useState } from 'react'

export const HouseToHouseSelector = () => {
    const config = useGetConfig();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();
    const [show, setShow] = useState(false)
    const [showAll, setShowAll] = useState(!user.hthAssignments?.length)
    const [showGeolocationModal, setShowGeolocationModal] = useState(false)
    const [showManualLocationModal, setShowManualLocationModal] = useState(false)

    return (<>

        <H2 title={"CASA EN CASA"} />

        {showManualLocationModal &&
            <ManualLocationModal
                closeGeolocationModalHandler={() => setShowManualLocationModal(false)}
            />
        }
        {showGeolocationModal &&
            <GeoLocationModal
                closeGeolocationModalHandler={() => setShowGeolocationModal(false)}
            />
        }

        <button className={`btn btn-general-blue w-100 mt-4`}
            onClick={() => setShow(x => !x)}
        >
            {show ? 'Ocultar' : 'Ver territorios'}
        </button>

        {show && <>
            <div className={'row mt-4'}>
                <div className={`col-md-3 offset-md-3 ${isMobile ? 'd-flex justify-content-center' : ''}`}>
                    <button className={`btn btn-general-blue ${isMobile ? 'w-75 mb-3' : 'w-100'}`}
                        onClick={() => setShowManualLocationModal(true)}
                    >
                        Dónde Estoy - Dirección
                    </button>
                </div>
                <div className={`col-md-3 ${isMobile ? 'd-flex justify-content-center' : ''}`}>
                    <button className={`btn btn-general-blue ${isMobile ? 'w-75' : 'w-100'}`}
                        onClick={() => setShowGeolocationModal(true)}
                    >
                        Dónde Estoy - Mapa
                    </button>
                </div>
            </div>

            {user.isBuildingsAdmin &&
                !(!!user.hthAssignments?.length && user.hthAssignments.length === config.numberOfTerritories) &&
                <button className={`btn btn-general-blue d-block mx-auto my-4 ${isMobile ? 'w-75' : 'w-25'}`}
                    onClick={() => setShowAll(x => !x)}
                >
                    {showAll ? 'Ver solo los asignados' : 'Ver todos los territorios'}
                </button>
            }

            {!!user.hthAssignments?.length ?
                <>
                    {user.hthAssignments.length === config.numberOfTerritories &&
                        <h3 className={`text-center ${isDarkMode ? 'text-white' : ''} mt-4`}>
                            Este usuario tiene asignados todos los territorios de Casa en Casa
                        </h3>
                    }
                </>
                :
                <h3 className={`text-center ${isDarkMode ? 'text-white' : ''}`}>
                    No hay territorios asignados de Casa en Casa
                </h3>
            }

            <TerritoryNumberBlock
                classes={'btn-general-blue animate__animated animate__rubberBand'}
                showForecast={true}
                territories={showAll ?
                    Array.from({ length: config.numberOfTerritories }, (_, i) => i + 1)
                    :
                    [...user.hthAssignments].sort((a, b) => a - b)
                }
                url={'/casa-en-casa'}
            />

        </>}

        {!show && <><br/><br/><br/></>}

    </>)
}
