import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Hr } from '../../commons';
import { typeHTHTerritory, typeUser } from '../../../models';
import { useGetIsDarkMode } from '../../commons/custom-hooks';

type propsType = {
    hthTerritories: typeHTHTerritory[];
    users: typeUser[] | null;
}

export const StatisticsHTHAssignments: FC<propsType> = ({ hthTerritories, users }) => {
    const [territoryToShow, setTerritoryToShow] = useState<typeHTHTerritory | null>(null);
    const isDarkMode = useGetIsDarkMode();

    return (
        <div className={'mt-4'}>

            <Hr classes={'mt-5 mx-5'} />

            <h3 className={`mt-4 ${isDarkMode ? 'text-white' : ''}`}>
                Asignaciones
            </h3>

            {hthTerritories.sort((a, b) => parseInt(a.territoryNumber) - parseInt(b.territoryNumber)).map(t =>
                <Territory key={t.territoryNumber}
                    setTerritoryToShow={setTerritoryToShow}
                    t={t}
                    territoryToShow={territoryToShow}
                    users={users}
                />
            )}
        </div>
    )
}

type propsType1 = {
    setTerritoryToShow: Dispatch<SetStateAction<typeHTHTerritory | null>>;
    t: typeHTHTerritory;
    territoryToShow: typeHTHTerritory | null;
    users: typeUser[] | null;
}

const Territory: FC<propsType1> = ({ setTerritoryToShow, t, territoryToShow, users }) => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <div className={'my-5'}>
            <div className={`card ${isDarkMode ? 'text-white' : ''}`}>
                <div className={`card-header ${isDarkMode ? 'bg-success' : ''} d-flex justify-content-between`}>
                    <div className={'fw-bold'}>
                        TERRITORIO {t.territoryNumber} - Edificios: {t.map.polygons.map(x => x.buildings?.length || 0).reduce((a, n) => a + n, 0)}
                    </div>
                </div>
                <div className={`card-body ${isDarkMode ? 'bg-dark' : ''}`}>
                    {users
                        ?.filter(u => u.hthAssignments.some(a => a.toString() === t.territoryNumber))
                        .sort((u1, u2) => u1.email.localeCompare(u2.email))
                        .map(u =>
                        <h5 key={u.id}>{u.email}</h5>
                    )}
                </div>
            </div>
        </div>
    )
}
