import { BsTrash } from 'react-icons/bs';
import { daysOfTheWeekP, months } from '../../../app-config';
import { deleteCartAssignmentService } from '../../../services/cartServices1';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { getTimeFromPeriodObject } from '../../../services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { typeNewCartOrganization, typeNewCartAssignment } from '../../../models';
import { useDispatch } from 'react-redux';
import { useGetIsMobile } from '../../commons/custom-hooks';

type propsType = {
    cartOrg: typeNewCartOrganization;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartAssignment: FC<propsType> = ({ cartOrg, setRefreshCounter }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showFormAdd, setShowFormAdd] = useState(false);
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();

    // const getMonthName = (mes: string) => {
    //     const [month, year] = mes.split('-');
    //     const date = new Date(Number(year), Number(month) - 1); // Ajusta el mes restando 1
    //     return capitalizeFirstLetter(date.toLocaleString('es-ES', { month: 'long', year: 'numeric' }));
    // }

    const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

    const filteredAssignments: typeNewCartAssignment[] = useMemo(() =>
        cartOrg.assignments.filter(a => {
            const lowerSearchTerm = searchTerm.toLowerCase();
            const monthMatch = months[a.month].toLowerCase().includes(lowerSearchTerm);
            // const monthStringMatch = getMonthName(a.month)?.toLowerCase().includes(lowerSearchTerm);
            const puntoMatch = a.name.toLowerCase().includes(lowerSearchTerm);
            // const diaMatch = a.horario.dia.toLowerCase().includes(lowerSearchTerm);
            const hermanoMatch = a.participants.some(p =>
                `${p.name.toLowerCase()} ${p.lastName.toLowerCase()}`.includes(lowerSearchTerm)
            );
            return puntoMatch || hermanoMatch || monthMatch;
        })
    , [cartOrg.assignments, searchTerm]);

    const handleDeleteTurno = (assignment: typeNewCartAssignment) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar este turno de ${assignment.participants[0].name} ${assignment.participants[0].lastName} y ${assignment.participants[1].name} ${assignment.participants[1].lastName} en ${assignment.name}. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteCartAssignmentService(assignment);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    return (
        <div className="container mt-3" style={{ maxWidth: '650px' }}>
            <div className="d-flex justify-content-between align-items-center my-4">
                <h2 className="text-center flex-grow-1">
                    {!showFormAdd ? <>{isMobile ? 'Turnos' : 'Listado de turnos'} - <span className={'small'}>Viendo {filteredAssignments.length}</span></> : 'Agregar turno'}
                </h2>
                <button className={`btn btn-general-${showFormAdd ? 'red' : 'blue'} ${isMobile ? 'btn-sm' : ''}`}
                    onClick={() => setShowFormAdd(!showFormAdd)}
                >
                    {showFormAdd ? 'Cancelar' : 'Agregar turno'}
                </button>
            </div>
            {!showFormAdd &&
                <div>
                    <input type="text"
                        className="form-control mb-4"
                        placeholder="Buscar por punto, día o hermano..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoFocus
                    />
                </div>
            }
            {!showFormAdd &&
                <div className="row">
                    {filteredAssignments?.map(a =>
                        <div key={a.id} className="col-12 mb-5">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title text-center">{a.name}</h5>
                                    <p className="card-text">
                                        Mes: <strong>{months[a.month]}</strong>
                                    </p>
                                    <p className="card-text">Dia: {daysOfTheWeekP[a.weekday]}</p>
                                    <p className="card-text">Horario: {getTimeFromPeriodObject(a)}</p>
                                    <div className="card-text ">
                                        Hermanos:
                                        <ul>
                                            {a.participants?.map(p =>
                                                <li key={p.userId}>
                                                    {p.name} {p.lastName}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-footer d-flex gap-4 justify-content-end">
                                    {/* <button className="btn btn-general-blue" onClick={() => handleEdit(turno)}>
                                        Editar <BsPen />
                                    </button> */}
                                    <button className="btn btn-general-red" onClick={() => handleDeleteTurno(a)}>
                                        Eliminar <BsTrash />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }

            {/* {showFormAdd &&
                <TurnosCarritosAgregar
                    cartOrg={cartOrg}
                    closeModal={() => setShowFormAdd(false)}
                    newTurno={null}
                    setRefreshCounter={setRefreshCounter}
                />
            } */}
        </div>
    )
}
