import { BsCalendar } from 'react-icons/bs';
import { CalendarioCarritosCalendario } from './CalendarioCarritosCalendario';
import { goToTop } from '../../../services';
import { months } from '../../../app-config';
import { typeCartOrganization } from '../../../models';
import { useState, useEffect, FC, Dispatch, SetStateAction } from 'react';

type propsType = {
    cartOrg: typeCartOrganization;
    nextMonth: boolean;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
    thisMonth: boolean;
}

export const CalendarioCarritos: FC<propsType> = ({ cartOrg, nextMonth, setRefreshCounter, thisMonth }) => {
    const [selectedMonth, setSelectedMonth] = useState<string>(
        nextMonth ?
        () => `${new Date().getFullYear() + (new Date().getMonth() === 11 ? 1 : 0)}-${String((new Date().getMonth() + 1) % 12 + 1).padStart(2, '0')}`
        :
        () => `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`
    );
    const monthName = thisMonth ? months[new Date().getMonth()].toUpperCase() : nextMonth ? months[(new Date().getMonth() + 1) % 12].toUpperCase() : '';

    useEffect(() => {
        goToTop();
    }, []);

    return (
        <div className="container mt-5">
            {(thisMonth || nextMonth) ?
                <h2 className={'btn-general-blue text-center py-3 mb-4'}>{monthName}</h2>
                :
                <div className={'ps-5'}>
                    <h3>Calendario de turnos <BsCalendar /></h3>
                    <div className="mb-4">
                        <label htmlFor="monthSelect" className="form-label">
                            Selecciona el mes:
                        </label>
                        <input type="month"
                            id="monthSelect"
                            className="form-control"
                            style={{ maxWidth: '300px' }}
                            value={selectedMonth}
                            onChange={e => setSelectedMonth(e.target.value)}
                        />
                        <div className={'mt-3'}>
                            <strong>Seleccionado:</strong> {selectedMonth}
                        </div>
                    </div>
                </div>
            }
            {cartOrg.puntos.map(punto =>
                <CalendarioCarritosCalendario key={punto.id}
                    cartOrg={cartOrg}
                    monthName={monthName}
                    punto={punto}
                    selectedMonth={selectedMonth}
                    setRefreshCounter={setRefreshCounter}
                />
            )}
        </div>
    )
}
