import { FC, Dispatch, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import { TurnosCarritosAgregar } from '../carritos-common/TurnosCarritosAgregar';
import { typeCartOrganization, typeTurno } from '../../../models';

type propsType = {
    cartOrg: typeCartOrganization;
    closeModal: () => void;
    newTurno: typeTurno;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CalendarioCarritosModalAgregar: FC<propsType> = ({
    cartOrg, closeModal, newTurno, setRefreshCounter
}) => {
    return (
        <Modal show={true} onHide={closeModal} size={'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>Agregar Turno</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TurnosCarritosAgregar
                    cartOrg={cartOrg}
                    closeModal={closeModal}
                    newTurno={newTurno}
                    setRefreshCounter={setRefreshCounter}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className={'btn btn-secondary'} onClick={closeModal}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
