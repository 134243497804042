import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { typeTurno } from '../../../models';

type propsType = {
    closeModal: () => void;
    selectedTurno: typeTurno;
}

export const CalendarioCarritosModalDetails: FC<propsType> = ({ closeModal, selectedTurno }) => {
    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>Información del Turno</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    <strong>Punto:</strong> {selectedTurno.punto}
                </p>
                <p>
                    <strong>Horario:</strong> {selectedTurno.horario.dia}, {selectedTurno.horario.horario}
                </p>
                <p>
                    <strong>Hermanos asignados:</strong>
                </p>
                <ul>
                    {selectedTurno.hermanos?.map((hermano, index) =>
                        <li key={index}>
                            {hermano.name} {hermano.lastName}
                        </li>
                    )}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <button className={'btn btn-secondary'} onClick={closeModal}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
