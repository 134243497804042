import { BsCardList, BsShare } from 'react-icons/bs';
import { Card } from 'react-bootstrap';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { DOMAIN } from '../../../app-config';
import { HermanoDetalleModal } from '../carritos-common/HermanoDetalleModal';
import { typeCartDetail, typeCartOrganization, typeSolicitud } from '../../../models';
import { useNavigate } from 'react-router';
import { WhatsappShareButton } from 'react-share';

type propsType = {
    cartOrg: typeCartOrganization;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const SolicitudesCarritos: FC<propsType> = ({ cartOrg }) => {
    const [showFormAdd, setShowFormAdd] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedSolicitud, setSelectedSolicitud] = useState<typeSolicitud | null>(null);
    const navigate = useNavigate();

    const handleOpenModal = (solicitud: typeSolicitud) => {
        setSelectedSolicitud(solicitud);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setSelectedSolicitud(null);
        setShowModal(false);
    }

    const handleAddToForm = () => {
        if (selectedSolicitud) {
            navigate('/gestor-carritos/hermanos', { state: { solicitud: selectedSolicitud } });
        }
    }

    return (
        <div className="container mt-3" style={{ maxWidth: '850px' }}>
            {showModal && selectedSolicitud &&
                <HermanoDetalleModal
                    closeModal={handleCloseModal}
                    hermano={selectedSolicitud as typeCartDetail}
                />
            }
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="text-center flex-grow-1">
                    {'Listado de solicitudes'} <BsCardList />
                </h3>
                <div>
                    <WhatsappShareButton
                        className={'btn btn-general-blue mt-2 mb-4 py-2 px-3'}
                        url={`${DOMAIN}/acceso?url=/gestor-carritos/autoregistro`}
                        windowWidth={660}
                        windowHeight={460}
                    >
                        Compartir invitación {'  '}
                        <BsShare />
                    </WhatsappShareButton>
                </div>
            </div>
            <div className="text-center">
                <p>Listado de hermanos que han solicitado sumarse</p>
            </div>

            {!showFormAdd &&
                <div className="row justify-content-center">
                    {cartOrg.solicitudes.map((solicitud, index) =>
                        <div key={index} className='d-flex justify-content-center flex-wrap flex-direction-column'>
                            <Card
                                className="mb-3 custom-card"
                                style={{ width: '25rem' }}
                                onClick={() => handleOpenModal(solicitud)}
                            >
                                <Card.Body className={`d-flex justify-content-between align-items-center ${solicitud.status === 'pending' ? 'bg-danger' : ''}`}>
                                    <div>
                                        <Card.Title className={'mb-0'}>
                                            {solicitud.name} {solicitud.lastName} {solicitud.status === 'pending' ? '(pendiente)' : ''}
                                        </Card.Title>
                                    </div>
                                    <div className="d-flex gap-2"></div>
                                </Card.Body>
                            </Card>
                        </div>
                    )}

                    {!cartOrg.solicitudes.length &&
                        <div className="text-center">
                            <div className="card p-3 mb-3 custom-card">
                                <strong>No hay solicitudes pendientes</strong>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}
