import { BsClock, BsPen, BsTrash } from 'react-icons/bs';
import { deleteHorarioService } from '../../../services/cartServices';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { HorariosCarritosAgregar } from './HorariosCarritosAgregar';
import { typeCartOrganization, typeHorario } from '../../../models';
import { useDispatch } from 'react-redux';

type propsType = {
    cartOrg: typeCartOrganization;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const HorariosCarritos: FC<propsType> = ({ cartOrg, setRefreshCounter }) => {
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [horarioToEdit, setHorarioToEdit] = useState<typeHorario | null>(null);
    const [showFormAdd, setShowFormAdd] = useState(false);
    const dispatch = useDispatch();

    const toggleFormAdd = () => {
        setShowFormAdd(!showFormAdd);
        if (showFormAdd) {
            setHorarioToEdit(null);
            setEditIndex(null);
        }
    }

    const handleSaveHorario = () => {
        setShowFormAdd(false);
        setHorarioToEdit(null);
        setEditIndex(null);
    }

    const handleEditHorario = (index: number) => {
        setHorarioToEdit(cartOrg.horarios[index]);
        setEditIndex(index);
        setShowFormAdd(true);
    }

    const handleDeleteHorario = (horario: typeHorario) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar el horario ${horario.start}-${horario.end}`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteHorarioService(horario);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    };

  return (
        <div className="container mt-3" style={{ maxWidth: '650px' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="text-center flex-grow-1">
                    {!showFormAdd ? 'Listado de horarios' : 'Agregar horario'} <BsClock />
                </h3>
                <button className={`btn btn-general-${showFormAdd ? 'red' : 'blue'}`} onClick={toggleFormAdd}>
                    {showFormAdd ? 'Cancelar' : 'Agregar horario'}
                </button>
            </div>

            {!showFormAdd &&
                <div className="row">
                    {/* {false && <div className="col-12 d-flex flex-wrap gap-3 mb-3">
                        <SkeletonLoader width="98%" height="145px"></SkeletonLoader>
                        <SkeletonLoader width="98%" height="145px"></SkeletonLoader>
                        <SkeletonLoader width="98%" height="145px"></SkeletonLoader>
                    </div>} */}
                    {cartOrg.horarios?.map((horario, index) =>
                        <div key={index} className="col-12 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Horario {index + 1}</h5>
                                    <p className="card-text">
                                        {horario.start} - {horario.end}
                                    </p>
                                </div>
                                <div className="card-footer d-flex gap-4 justify-content-end">
                                    <button className="btn btn-general-blue" onClick={() => handleEditHorario(index)}>
                                        Editar <BsPen />
                                    </button>
                                    <button className="btn btn-general-red" onClick={() => handleDeleteHorario(horario)}>
                                        Eliminar <BsTrash />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }

            {showFormAdd &&
                <HorariosCarritosAgregar
                    editIndex={editIndex}
                    horarioToEdit={horarioToEdit}
                    onSave={handleSaveHorario}
                    setRefreshCounter={setRefreshCounter}
                />
            }
        </div>
    )
}
