import { DisponibilidadCarritosForm } from '../carritos-common/DisponibilidadCarritosForm';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { typeCartOrganization, typeDisponibilidad, typeHermano } from '../../../models';
import { updateHermanoService } from '../../../services/cartServices';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser } from '../../commons/custom-hooks';
import { useState, useEffect, SetStateAction, Dispatch, FormEvent } from 'react';

type propsType = {
    cartOrg: typeCartOrganization;
    onSave: () => void;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const HermanosCarritosUpdateDisponibilidad: React.FC<propsType> = ({ cartOrg, onSave, setRefreshCounter }) => {
    const [selectedHermano, setSelectedHermano] = useState<typeHermano | null>(null);
    const [disponibilidad, setDisponibilidad] = useState<typeDisponibilidad[]>([]);
    const dispatch = useDispatch();
    const user = useGetCurrentUser();
    
    useEffect(() => {
        const hermano = cartOrg.hermanos.find(h => h.user.id === user.id);
        if (hermano) setSelectedHermano(hermano);
    }, [cartOrg.hermanos, user.id]);

    useEffect(() => {
        if (selectedHermano) {
            setDisponibilidad(selectedHermano.disponibilidad);
        }
    }, [selectedHermano]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!selectedHermano) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a actualizar la disponibilidad de ${selectedHermano.name} ${selectedHermano.lastName}`,
            execution: async () => {
                const updatedHermano = {
                    ...selectedHermano,
                    disponibilidad
                }
                dispatch(showLoadingModalReducer());
                const success = await updateHermanoService(updatedHermano);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    onSave();
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo editar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    return (
        <div className="container p-2">
            <form onSubmit={handleSubmit}>
                <div className="mb-3 row">
                    <div className="col-md-6 col-sm-12 mb-2">
                        <label className="form-label">Hermano: </label>
                        <h3>{selectedHermano?.name + ' ' + selectedHermano?.lastName}</h3>
                    </div>
                </div>
                <DisponibilidadCarritosForm
                    cartOrg={cartOrg}
                    disponibilidad={disponibilidad}
                    setDisponibilidad={setDisponibilidad}
                />
                <div className="d-flex justify-content-center">
                    <button type="submit"
                        className="btn btn-primary mt-3"
                        disabled={!selectedHermano || disponibilidad.length === 0}
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </div>
    )
}
