import { getHeaders } from './helpers';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeNewCartAssignment, typeNewCartOrganization, typeNewParticipant, typeNewPeriod, typeNewPoint } from '../models';

const base = pointer.carts1;

// export const addParticipantService = async (participant: typeNewParticipant): Promise<boolean> => {
//     if (!getTokenFromLSService()) return false;
//     try {
//         const response = await fetch(`${base}/participant`, {
//             method: 'POST',
//             headers: getHeaders(),
//             body: JSON.stringify({ participant })
//         });
//         const data = await response.json();
//         return !!data?.success;
//     } catch (error) {
//         console.log(error);
//         return false;
//     }
// }

export const addCartEnabledPeriodService = async (point: typeNewPoint, period: typeNewPeriod): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/period`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ period, point })
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addCartPointService = async (point: typeNewPoint): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/point`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ point })
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

// export const addSolicitudService = async (newSolicitud: typeSolicitud): Promise<boolean> => {
//     if (!getTokenFromLSService()) return false;
//     try {
//         const response = await fetch(`${base}/solicitudes`, {
//             method: 'POST',
//             headers: getHeaders(),
//             body: JSON.stringify(newSolicitud)
//         });
//         const data = await response.json();
//         return !!data?.success;
//     } catch (error) {
//         console.log(error);
//         return false;
//     }
// }

// export const addTurnoService = async (newTurno: typeTurno): Promise<boolean> => {
//     if (!getTokenFromLSService()) return false;
//     try {
//         const response = await fetch(`${base}/turnos`, {
//             method: 'POST',
//             headers: getHeaders(),
//             body: JSON.stringify(newTurno)
//         });
//         const data = await response.json();
//         return !!data?.success;
//     } catch (error) {
//         console.log(error);
//         return false;
//     }
// }

// export const deleteHermanoService = async (hermano: typeHermano): Promise<boolean> => {
//     if (!getTokenFromLSService()) return false;
//     try {
//         const response = await fetch(`${base}/hermanos`, {
//             method: 'DELETE',
//             headers: getHeaders(),
//             body: JSON.stringify(hermano)
//         });
//         const data = await response.json();
//         return !!data?.success;
//     } catch (error) {
//         console.log(error);
//         return false;
//     }
// }

export const deleteCartEnabledPeriodService = async (point: typeNewPoint, period: typeNewPeriod): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/period`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ period, point })
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteCartPointService = async (point: typeNewPoint): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/point`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ point })
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteCartAssignmentService = async (assignment: typeNewCartAssignment): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/assignment`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify({ assignment })
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const getCartOrganizationService = async (): Promise<typeNewCartOrganization | null> => {
    if (!getTokenFromLSService()) return null;
    try {
        const response = await fetch(base, {
            headers: getHeaders()
        });
        const data = await response.json();
        return data?.cartOrg;
    } catch (error) {
        console.log(error);
        return null;
    }
}

// export const updateHermanoService = async (hermano: typeHermano): Promise<boolean> => {
//     if (!getTokenFromLSService()) return false;
//     try {
//         const response = await fetch(`${base}/hermanos`, {
//             method: 'PUT',
//             headers: getHeaders(),
//             body: JSON.stringify(hermano)
//         });
//         const data = await response.json();
//         return !!data?.success;
//     } catch (error) {
//         console.log(error);
//         return false;
//     }
// }

export const updateCartPointService = async (point: typeNewPoint): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/point`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify({ point })
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

// export const updateSolicitudService = async (solicitud: typeSolicitud): Promise<boolean> => {
//     if (!getTokenFromLSService()) return false;
//     try {
//         const response = await fetch(`${base}/puntos`, {
//             method: 'PUT',
//             headers: getHeaders(),
//             body: JSON.stringify({ solicitud })
//         });
//         const data = await response.json();
//         return !!data?.success;
//     } catch (error) {
//         console.log(error);
//         return false;
//     }
// }
