import { addHorarioService, updateHorarioService } from '../../../services/cartServices';
import { goToTop } from '../../../services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { typeHorario } from '../../../models';
import { useDispatch } from 'react-redux';
import { useState, useEffect, FC, Dispatch, SetStateAction, FormEvent } from 'react';

type propsType = {
    editIndex: number | null;
    horarioToEdit: typeHorario | null;
    onSave: () => void;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const HorariosCarritosAgregar: FC<propsType> = ({ editIndex, horarioToEdit, onSave, setRefreshCounter }) => {
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        goToTop();
        if (horarioToEdit) {
            setStart(horarioToEdit.start);
            setEnd(horarioToEdit.end);
        }
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editIndex === null) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'confirm',
                title: 'Confirmar',
                message: `Se va a agregar un horario: ${start} - ${end} horas`,
                execution: async () => {
                    const newHorario = { start, end }
                    dispatch(showLoadingModalReducer());
                    const success = await addHorarioService(newHorario);
                    dispatch(hideLoadingModalReducer());
                    if (success) {
                        setStart('');
                        setEnd('');
                        onSave();
                        setRefreshCounter(x => x + 1);
                    } else {
                        dispatch(setValuesAndOpenAlertModalReducer({
                            title: 'Error',
                            message: 'No se pudo agregar',
                            animation: 2,
                            mode: 'alert'
                        }));
                    }
                }
            }));
        } else {
            if (!horarioToEdit) return;
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'confirm',
                title: 'Confirmar',
                message: `Se va a modificar el horario: ${start} - ${end} horas`,
                execution: async () => {
                    const horarioEditado: typeHorario = { start, end, id: horarioToEdit.id }
                    dispatch(showLoadingModalReducer());
                    const success = await updateHorarioService(horarioEditado);
                    dispatch(hideLoadingModalReducer());
                    if (success) {
                        setStart('');
                        setEnd('');
                        onSave();
                        setRefreshCounter(x => x + 1);
                    } else {
                        dispatch(setValuesAndOpenAlertModalReducer({
                            title: 'Error',
                            message: 'No se pudo editar',
                            animation: 2,
                            mode: 'alert'
                        }));
                    }
                }
            }));
        }
    }

    return (
        <form onSubmit={handleSubmit} className="mt-3">
            <div className="mb-3">
                <label className="form-label">Hora de inicio</label>
                <input type="time" className="form-control" value={start} onChange={e => setStart(e.target.value)} required />
            </div>
            <div className="mb-3">
                <label className="form-label">Hora de fin</label>
                <input type="time" className="form-control" value={end} onChange={e => setEnd(e.target.value)} required />
            </div>
            <div className="d-flex justify-content-center mb-4 mt-4">
                <button disabled={!start || !end} type="submit" className="btn btn-general-blue ">
                    {editIndex !== null ? 'Guardar Cambios' : 'Agregar Horario'}
                </button>
            </div>
        </form>
    )
}
