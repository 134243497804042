import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { prioridades, typeCartDetail } from '../../../models';

type propsType = {
    closeModal: () => void;
    hermano: typeCartDetail;
}

export const HermanoDetalleModal: FC<propsType> = ({ closeModal, hermano }) => {
    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Detalles de {hermano?.name} {hermano?.lastName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {hermano &&
                    <div>
                        <p>
                            <strong>Nombre:</strong> {hermano.name}
                        </p>
                        <p>
                            <strong>Apellido:</strong> {hermano.lastName}
                        </p>
                        {hermano.phone &&
                            <p>
                                <strong>Teléfono:</strong> {hermano.phone}
                            </p>
                        }
                        <p>
                            <strong>Participó:</strong> {hermano.participo ? 'Sí' : 'No'}
                        </p>
                        {hermano.prioridad &&
                            <p>
                                <strong>Prioridad:</strong> {prioridades.find(p => p.valor === hermano.prioridad)?.etiqueta}
                            </p>
                        }
                        <div className="mb-4">
                            <strong>Comentarios:</strong>{' '}
                            <div>{hermano.comentarios ? hermano.comentarios : 'Sin comentarios'}</div>
                        </div>
                        <p>
                            <strong>Disponibilidad:</strong>
                        </p>
                        {hermano.disponibilidad?.map((disponibilidad, index) =>
                            <div key={index} className={'ms-2'}>
                                <strong>{disponibilidad.punto}</strong>
                                <ul>
                                    {disponibilidad.horarios?.map((horario, idx) =>
                                        <li key={idx}>
                                            {horario.dia}: {horario.horarios.join(', ')}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className={'btn btn-secondary'} onClick={closeModal}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
