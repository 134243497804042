import { addSolicitudService } from '../../../services/cartServices';
import { DisponibilidadCarritosForm } from '../carritos-common/DisponibilidadCarritosForm';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { typeCartOrganization, typeDisponibilidad, typeSolicitud } from '../../../models';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser } from '../../commons/custom-hooks';
import { useNavigate } from 'react-router';
import { useState, FC, Dispatch, SetStateAction, FormEvent, useMemo } from 'react';

type propsType = {
    cartOrg: typeCartOrganization;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const HermanosCarritosAutoRegistro: FC<propsType> = ({ cartOrg }) => {
    const [comentarios, setComentarios] = useState('');
    const [disponibilidad, setDisponibilidad] = useState<typeDisponibilidad[]>([]);
    const [lastName, setLastName] = useState('');
    const [name, setName] = useState('');
    const [participo, setParticipo] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const isValid: boolean = useMemo(() =>
        !!name && !!lastName && !!disponibilidad.length
    , [disponibilidad, lastName, name]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isValid) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a agregar una solicitud de ${name} ${lastName}`,
            execution: async () => {
                const newSolicitud: typeSolicitud = {
                    comentarios,
                    disponibilidad,
                    id: Date.now(),
                    lastName,
                    name,
                    participo,
                    status: 'pending',
                    user
                };
                dispatch(showLoadingModalReducer());
                const success = await addSolicitudService(newSolicitud);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    navigate('/');
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo agregar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    if (cartOrg.solicitudes.some(s => s.user.id === user.id)) return (
        <div className={'mt-5'}>Ya se registró una solicitud</div>
    );

    return (
        <div className="container p-4">
            <div className="text-center mb-4 mt-3">
                <h2>Solicitud para participar en los carritos</h2>
                <p className='text-muted'>INVITAMOS A INSCRIBIRSE (2 Timoteo 4:5 )</p>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="mb-3 row">
                    <div className="col-md-6 col-sm-12">
                        <label className="form-label">Nombre</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingrese nombre"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <label className="form-label">Apellido</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Ingrese apellido"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-12 mt-4">
                        <label className="form-label">Participo actualmente de la predicación con carritos</label>
                        <div className="form-check">
                            <input
                                type="radio"
                                className="form-check-input"
                                id="participoSi"
                                name="participo"
                                value="true"
                                checked={participo === true}
                                onChange={(e) => setParticipo(true)}
                            />
                            <label className="form-check-label" htmlFor="participoSi">Sí</label>
                        </div>
                        <div className="form-check">
                            <input
                                type="radio"
                                id="participoNo"
                                name="participo"
                                className="form-check-input"
                                value="false"
                                checked={participo === false}
                                onChange={(e) => setParticipo(false)}
                            />
                            <label className="form-check-label" htmlFor="participoNo">No</label>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 mt-4">
                        <label className="form-label">Comentarios adicionales</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Ingrese comentarios, sugerencias, etc(opcional)'
                            value={comentarios}
                            onChange={(e) => setComentarios(e.target.value)}
                        />
                    </div>
                </div>

                <hr />

                <div className="row">
                    <DisponibilidadCarritosForm
                        cartOrg={cartOrg}
                        disponibilidad={disponibilidad}
                        setDisponibilidad={setDisponibilidad}
                    />
                </div>
                <div className="d-flex justify-content-center">
                    <button type="submit"
                        className="btn btn-general-blue mt-3 mb-3 btn-block btn-lg"
                        style={{ width: '50%' }}
                        disabled={!isValid}
                    >
                        Enviar solicitud
                    </button>
                </div>
            </form>
        </div>
    )
}
