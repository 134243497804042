import { BsPeople, BsPinMap, BsClock, BsCalendar, BsPen, BsCardList } from 'react-icons/bs';
import { CalendarioCarritos } from '../carritos/calendario/CalendarioCarritos';
import { getCartOrganizationService } from '../../services/cartServices';
import { goToTop } from '../../services';
import { HermanosCarritos } from '../carritos/hermanos/HermanosCarritos';
import { HermanosCarritosAutoRegistro } from '../carritos/hermanos/HermanosCarritosAutoregistro';
import { HermanosCarritosUpdateDisponibilidad } from '../carritos/hermanos/HermanosCarritosUpdateDisponibilidad';
import { HorariosCarritos } from '../carritos/horarios/HorariosCarritos';
import { H2, Loading } from '../commons';
import { PuntosCarritos } from '../carritos/puntos/PuntosCarritos';
import { SolicitudesCarritos } from '../carritos/solicitudes/SolicitudesCarritos';
import { TurnosCarritos } from '../carritos/turnos/TurnosCarritos';
import { typeCartOrganization } from '../../models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

type typeCarritosSection =
  | ''
  | 'hermanos'
  | 'puntos'
  | 'horarios'
  | 'calendario'
  | 'turnos'
  | 'update-disponibilidad'
  | 'autoregistro'
  | 'solicitudes';
  
const getSection = (): typeCarritosSection => {
    const relativePath = window.location.pathname;
    if (relativePath === '/gestor-carritos/hermanos') return 'hermanos';
    if (relativePath === '/gestor-carritos/puntos') return 'puntos';
    if (relativePath === '/gestor-carritos/horarios') return 'horarios';
    if (relativePath === '/gestor-carritos/calendario') return 'calendario';
    if (relativePath === '/gestor-carritos/turnos') return 'turnos';
    if (relativePath === '/gestor-carritos/update-disponibilidad') return 'update-disponibilidad';
    if (relativePath === '/gestor-carritos/autoregistro') return 'autoregistro';
    if (relativePath === '/gestor-carritos/solicitudes') return 'solicitudes';
    return '';
}

export const CartsPage = () => {
    const [cartOrg, setCartOrg] = useState<typeCartOrganization | null>(null);
    const [currentSection, setCurrentSection] = useState<typeCarritosSection>(getSection());
    const [isLoading, setIsLoading] = useState(true);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        getCartOrganizationService().then(co => {
            setIsLoading(false);
            console.log(co);
            if (co) setCartOrg(co);
        });
    }, [refreshCounter]);

    useEffect(() => {
        setCurrentSection(getSection());
        goToTop();
    // eslint-disable-next-line
    }, [window.location.pathname]);

    if (isLoading) return <Loading mt={'80px'} />;

    if (!cartOrg) return <div className={'mt-5'}>No se pudieron recuperar los datos</div>;

    return (
        <div>
            <H2 title={'CARRITOS'} />
            <section className="mb-4 px-4 mx-auto">
                {currentSection === '' &&
                    <div className="d-grid gap-3 m-auto mt-2" style={{ maxWidth: '600px' }}>
                        <button className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center mt-4"
                            onClick={() => navigate('puntos')}
                        >
                            <BsPinMap className="me-2" />
                            Puntos
                        </button>
                        <button className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center"
                            onClick={() => navigate('horarios')}
                        >
                            <BsClock className="me-2" />
                            Horarios
                        </button>
                        <button className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center"
                            onClick={() => navigate('hermanos')}
                            >
                            <BsPeople className="me-2" />
                            Hermanos
                        </button>
                        <button className={'btn btn-size12 d-flex align-items-center justify-content-center ' + ((cartOrg.solicitudes.filter(x => x.status === 'pending').length ?? 0) > 0 ? 'btn-general-red' : 'btn-general-blue')} 
                            onClick={() => navigate('solicitudes')}
                        >
                            <BsCardList className="me-2" /> 
                            {(cartOrg.solicitudes.filter(x => x.status === 'pending').length ?? 0) > 0 ? `Solicitudes pendientes:  (${(cartOrg.solicitudes.filter(x => x.status === 'pending').length ?? 0)})`  : 'No hay solicitudes pendientes'}
                        </button>

                        <button className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center"
                            onClick={() => navigate('calendario')}
                        >
                            <BsCalendar className="me-2" /> Calendario
                        </button>
                        <button className="btn btn-general-red btn-size12 d-flex align-items-center justify-content-center"
                            style={{ height: '65px' }}
                            onClick={() => navigate('turnos')}
                        >
                            <BsPen className="me-2" />
                            <h3 className="mb-0">Asignacion de turnos</h3>
                        </button>
                    </div>
                }
            </section>

            <div className="card shadow mx-auto">
                {currentSection === 'turnos' &&
                    <TurnosCarritos
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
                {currentSection === 'horarios' &&
                    <HorariosCarritos
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
                {currentSection === 'puntos' &&
                    <PuntosCarritos
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
                {currentSection === 'hermanos' &&
                    <HermanosCarritos
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
                {currentSection === 'calendario' &&
                    <CalendarioCarritos
                        cartOrg={cartOrg}
                        nextMonth={false}
                        setRefreshCounter={setRefreshCounter}
                        thisMonth={false}
                    />
                }
                {/* no se estaría usando: */}
                {currentSection === 'update-disponibilidad' &&
                    <HermanosCarritosUpdateDisponibilidad
                        cartOrg={cartOrg}
                        onSave={() => setCurrentSection('hermanos')}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
                {currentSection === 'autoregistro' &&
                    <HermanosCarritosAutoRegistro
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
                {currentSection === 'solicitudes' &&
                    <SolicitudesCarritos
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
            </div>
        </div>
    )
}
