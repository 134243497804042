import { FC, Fragment, useEffect, useMemo, useState } from 'react'
import { FloatingLabel, Form, ListGroup } from 'react-bootstrap'
import { getFreeHouseholds, timeConverter } from '../../services'
import { HTHBuildingModal } from '.'
import { HTHBuildingsFilter } from './HTHBuildingsFilter'
import { typeBlock, typeHTHBuilding, typeHTHTerritory, typePolygon } from '../../models'
import { useGetIsDarkMode } from '../commons/custom-hooks'

type propsType = {
    block: typeBlock|null
    congregation: number;
    territoryHTH: typeHTHTerritory
    usingLettersForBlocks: boolean
}

export const HTHAllBuildings: FC<propsType> = ({
    block, congregation, territoryHTH, usingLettersForBlocks
}) => {
    const isDarkMode = useGetIsDarkMode();
    const [buildingAddress, setBuildingAddress] = useState("")
    const [currentBuilding, setCurrentBuilding] = useState<typeHTHBuilding|null>(null)
    const [currentFace, setCurrentFace] = useState<typePolygon|null>(null)
    const [filter, setFilter] = useState([...new Set(territoryHTH.map.polygons.map(p => p.block))])
    const [selectedAddress, setSelectedAddress] = useState("")

    const allBuildings: (typeHTHBuilding & { block: typeBlock; face: typePolygon; })[] = useMemo(() =>
        territoryHTH.map.polygons
            .map(p => p.buildings ? p.buildings.map(b => ({ ...b, face: p, block: p.block})) : [])
            .filter(p => p.length)
            .flat()
            .filter(p => filter.includes(p.block))
            .sort((a, b) => a.face.street.localeCompare(b.face.street) || (a.streetNumber - b.streetNumber))
    , [filter, territoryHTH.map.polygons])

    const closeBuildingModalHandler = () => {
        setCurrentBuilding(null)
        setCurrentFace(null)
        setSelectedAddress("")
    }

    useEffect(() => {
        setCurrentFace(x => x ?
            territoryHTH.map.polygons.find(p => p.id === x.id) ?? null
            :
            x
        )
    }, [territoryHTH])

    useEffect(() => {
        setCurrentBuilding(x => x ?
            currentFace?.buildings?.find(b => b.streetNumber === x.streetNumber) ?? null
            :
            x
        )
    }, [currentFace])

    return (<>
        {currentFace && currentBuilding &&
            <HTHBuildingModal
                closeBuildingModalHandler={closeBuildingModalHandler}
                congregation={congregation}
                currentBuilding={currentBuilding}
                currentFace={currentFace}
                territoryNumber={territoryHTH.territoryNumber}
            />
        }

        {!block &&
            <HTHBuildingsFilter
                filter={filter}
                setFilter={setFilter}
                territoryHTH={territoryHTH}
                usingLettersForBlocks={usingLettersForBlocks}
            />
        }

        {!!allBuildings?.length ?
            <>
            <div className={'container maxw-400'}>
                <h1 className={`text-center mb-5 ${isDarkMode ? 'text-white' : ''}`}>
                    BUSCAR EDIFICIO<span className={'h5 fw-normal ms-1'}>({allBuildings.length})</span>
                </h1>
                <FloatingLabel
                    className={'mb-3 text-dark'}
                    label={"Dirección"}
                >
                    <Form.Control
                        type={'text'}
                        className={'form-control'}
                        placeholder={""}
                        value={buildingAddress}
                        onChange={e => setBuildingAddress((e.target as HTMLInputElement).value)}
                        // autoFocus
                    />
                </FloatingLabel>
                <ListGroup as={'ul'}>
                    {allBuildings.map(b =>
                        <Fragment key={b.face.street + "-" + b.streetNumber}>
                            {(`${b.face.street} ${b.streetNumber}`.toLowerCase().includes(buildingAddress.toLowerCase()) ||
                                `${b.face.street} ${b.streetNumber2}`.toLowerCase().includes(buildingAddress.toLowerCase()) ||
                                `${b.face.street} ${b.streetNumber3}`.toLowerCase().includes(buildingAddress.toLowerCase())
                            ) && (
                                () => {
                                    const lastTime = b.households
                                        .map(h => h.onDates?.length ? h.onDates[h.onDates.length - 1] : 0)
                                        .sort((a, b) => a - b)?.at(-1)
                                    return (
                                        <ListGroup.Item
                                            as={'li'}
                                            className={'pointer bg-light bg-gradient hover-primary'}
                                            active={selectedAddress === `${b.face.street} ${b.streetNumber}`}
                                            onClick={() => {
                                                setSelectedAddress(`${b.face.street} ${b.streetNumber}`)
                                                setCurrentFace(b.face)
                                                setCurrentBuilding(b)
                                            }}
                                        >
                                            <div className='row py-2'>
                                                <h5 className='text-center'>
                                                    {b.face.street} {b.streetNumber}{b.streetNumber2 ? `/${b.streetNumber2}` : ''}{b.streetNumber3 ? `/${b.streetNumber3}` : ''}
                                                </h5>
                                            </div>
                                            <div className='row text-center'>
                                                <span className='text-center'>
                                                    {getFreeHouseholds(b)} libres | {lastTime ? timeConverter(lastTime) : '-'}
                                                </span>
                                            </div>
                                        </ListGroup.Item>
                                    )
                                })()
                            }
                        </Fragment>
                    )}
                </ListGroup>
            </div>
            </>
            :
            <h4 className={`text-center mt-4 ${isDarkMode ? 'text-white' : ''}`}>
                {!!filter.length ? 'No hay Edificios cargados en este Territorio' : 'Habilitar alguna manzana'}
            </h4>
        }
    </>)
}
