import { getHeaders } from './helpers';
import { getTokenFromLSService } from './localStorageServices';
import { pointer } from '../app-config';
import { typeCartOrganization, typeHermano, typeHorario, typePunto, typeSolicitud, typeTurno } from '../models';

const base = pointer.carts;

export const addHermanoService = async (newHermano: typeHermano): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/hermanos`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(newHermano)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addHorarioService = async (newHorario: typeHorario): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/horarios`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(newHorario)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addPuntoService = async (newPunto: typePunto): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/puntos`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(newPunto)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addSolicitudService = async (newSolicitud: typeSolicitud): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/solicitudes`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(newSolicitud)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addTurnoService = async (newTurno: typeTurno): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/turnos`, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(newTurno)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteHermanoService = async (hermano: typeHermano): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/hermanos`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify(hermano)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteHorarioService = async (horario: typeHorario): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/horarios`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify(horario)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deletePuntoService = async (punto: typePunto): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/puntos`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify(punto)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteTurnoService = async (turno: typeTurno): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/turnos`, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify(turno)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const getCartOrganizationService = async (): Promise<typeCartOrganization | null> => {
    if (!getTokenFromLSService()) return null;
    try {
        const response = await fetch(base, {
            headers: getHeaders()
        });
        const data = await response.json();
        return data?.cartOrg;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const updateHermanoService = async (hermano: typeHermano): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/hermanos`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(hermano)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const updateHorarioService = async (horario: typeHorario): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/horarios`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(horario)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const updatePuntoService = async (punto: typePunto): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/puntos`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(punto)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const updateSolicitudService = async (solicitud: typeSolicitud): Promise<boolean> => {
    if (!getTokenFromLSService()) return false;
    try {
        const response = await fetch(`${base}/puntos`, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(solicitud)
        });
        const data = await response.json();
        return !!data?.success;
    } catch (error) {
        console.log(error);
        return false;
    }
}
