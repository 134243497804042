import { addTurnoService } from '../../../services/cartServices';
import { daysOfWeekDesktop, generalRed } from '../../../app-config';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { prioridades, typeCartOrganization, typeHermano, typeTurno } from '../../../models';
import { useDispatch } from 'react-redux';
import { useState, FC, Dispatch, SetStateAction, useMemo } from 'react';

type propsType = {
    cartOrg: typeCartOrganization;
    closeModal: () => void;
    newTurno: typeTurno | null;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const TurnosCarritosAgregar: FC<propsType> = ({ cartOrg, closeModal, newTurno, setRefreshCounter }) => {
    const [month, setMonth] = useState<string>(newTurno?.mes ?? '');
    const [selectedDia, setDia] = useState<string>(newTurno?.horario.dia ?? '');
    const [selectedHorario, setHorario] = useState<string>(newTurno?.horario.horario ?? '');
    const [selectedMonth, setSelectedMonth] = useState<string>(newTurno?.mes.split('-').reverse().join('-') ?? '');
    const [selectedParticipantes, setSelectedParticipantes] = useState<typeHermano[]>([]);
    const [selectedPunto, setPunto] = useState<string>(newTurno?.punto ?? '');
    const dispatch = useDispatch();

    const suggestions:typeTurno[] = useMemo(() => {
        const suggestions: typeTurno[] = [];
        const disponibilidadMap = new Map();
        cartOrg.hermanos.forEach(hermano => {
            hermano.disponibilidad.forEach(disponibilidad => {
                disponibilidad.horarios.forEach(horario => {
                    const key = `${disponibilidad.punto}|${horario.dia}|${horario.horarios.join(',')}`;
                    if (!disponibilidadMap.has(key)) {
                        disponibilidadMap.set(key, []);
                    }
                    disponibilidadMap.get(key).push(hermano);
                });
            });
        });
        disponibilidadMap.forEach((hermanos, key) => {
            if (hermanos.length >= 2) {
                const [punto, dia, horario] = key.split('|');
                suggestions.push({
                    punto,
                    id: Date.now().toString() + key,
                    mes: '',
                    horario: { dia, horario },
                    hermanos
                });
            }
        });
        return suggestions;
    }, [cartOrg.hermanos]);

    const hermanosFiltrados: typeHermano[] = useMemo(() => {
        setSelectedParticipantes([]);
        const hermanosFiltradosPorPunto = cartOrg.hermanos.filter(hermano => {
            return hermano.disponibilidad.some(disponibilidad => {
                return disponibilidad.punto === selectedPunto;
            });
        });
        const hermanosFiltradosPorDiaPunto = hermanosFiltradosPorPunto.filter(hermano => {
            return hermano.disponibilidad.some(disponibilidad => {
                return disponibilidad.horarios.some(horario => {
                    return horario.dia === selectedDia;
                });
            });
        });
        const hermanosFiltradosPorDiaPuntoHorario = hermanosFiltradosPorDiaPunto.filter(hermano => {
            return hermano.disponibilidad.some(disponibilidad => {
                return disponibilidad.horarios.some((horario) => {
                    return horario.dia === selectedDia && horario.horarios.includes(selectedHorario.replaceAll('"', ''));
                });
            });
        });
        return hermanosFiltradosPorDiaPuntoHorario;
    }, [cartOrg.hermanos, selectedDia, selectedHorario, selectedPunto]);

    const isValid: boolean = useMemo(() =>
        !!selectedMonth && !!selectedPunto && !!selectedDia && !!selectedHorario && selectedParticipantes.length === 2
    , [selectedMonth, selectedPunto, selectedDia, selectedHorario, selectedParticipantes]);

    const handleAddTurno = async () => {
        if (!isValid) return;
        const newTurno: typeTurno = {
            id: Date.now().toString(),
            mes: month,
            punto: selectedPunto,
            horario: { dia: selectedDia, horario: selectedHorario.replaceAll('"', '') },
            hermanos: selectedParticipantes
        };
        // Verificar si el turno ya existe
        const turnoExistente = cartOrg.turnos.find(turno => {
            const { id, ...turnoSinId } = turno; // Copia sin el id
            return (
                turnoSinId.mes === newTurno.mes &&
                turnoSinId.punto === newTurno.punto &&
                turnoSinId.horario.dia === newTurno.horario.dia &&
                turnoSinId.horario.horario === newTurno.horario.horario &&
                turnoSinId.hermanos.length === newTurno.hermanos.length &&
                turnoSinId.hermanos.every((hermano, index) => hermano.user.id === newTurno.hermanos[index].user.id)
            );
        });
        if (turnoExistente) {
            dispatch(
                setValuesAndOpenAlertModalReducer({
                mode: 'confirm',
                title: 'Atención',
                message: 'Ya existe un turno identico',
                animation: 2
                })
            );
            return;
        }
        // Verificar superposición de turnos
        const hermanosSuperpuestos: Set<string> = new Set();
        const superposicion = cartOrg.turnos.some(turno => {
            const mismoHorario =
                turno.horario.dia === newTurno.horario.dia &&
                turno.horario.horario === newTurno.horario.horario &&
                turno.mes === newTurno.mes;
            turno.hermanos.forEach(hermano => {
                const existe = newTurno.hermanos.some((nuevoHermano) => hermano.user.id === nuevoHermano.user.id);
                if (existe) {
                    hermanosSuperpuestos.add(`${hermano.name} ${hermano.lastName}`);
                }
            });
            const hermanosEnComun = hermanosSuperpuestos.size > 0;
            return mismoHorario && hermanosEnComun;
        });
        if (superposicion) {
            const mensajeSuperposicion = `Existe una superposición de turnos con los siguientes participantes: ${Array.from(
                hermanosSuperpuestos
            ).join(', ')}`;
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'confirm',
                title: 'Atención',
                message: mensajeSuperposicion,
                animation: 2
            }));
            return;
        }
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a agregar un turno en ${newTurno.punto} para el mes ${newTurno.mes}, día ${newTurno.horario.dia} ${newTurno.horario.horario} horas, para ${newTurno.hermanos[0].name} ${newTurno.hermanos[0].lastName} y ${newTurno.hermanos[1].name} ${newTurno.hermanos[1].lastName}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await addTurnoService(newTurno);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    closeModal();
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo agregar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    const addRemoveParticipante = (hermano: typeHermano) => {
        let newParticipantes;
        if (selectedParticipantes.includes(hermano)) {
            newParticipantes = selectedParticipantes.filter((participante) => participante !== hermano);
        } else {
            newParticipantes = [...selectedParticipantes, hermano];
        }
        // Eliminar duplicados
        const uniqueParticipantes = Array.from(new Set(newParticipantes));
        setSelectedParticipantes(uniqueParticipantes);
    };

    const handleMonthChange = (e: any) => {
        setSelectedMonth(e);
        let month = '';
        const regex = /^\d{4}-(0[1-9]|1[0-2])$/;
        if (!regex.test(e)) setMonth(e);
        const [year, monthNumber] = e.split('-');
        month = `${monthNumber}-${year}`;
        setMonth(month);
    };

    const handleSuggestionClick = (suggestion: any) => {
        setPunto(suggestion.punto);
        setDia(suggestion.dia);
        setHorario(JSON.stringify(suggestion.horario));
    }

    return (
        <div className="container mt-3 mb-3">
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Mes</label>
                        <input type="month"
                            className="form-control"
                            value={selectedMonth}
                            onChange={e => handleMonthChange(e.target.value)}
                            disabled={!!newTurno}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Punto</label>
                        <select className="form-control"
                            value={selectedPunto}
                            onChange={e => setPunto(e.target.value)}
                            disabled={!!newTurno}
                        >
                            <option value="" disabled>Selecciona un punto</option>
                            {cartOrg.puntos.map(punto =>
                                <option key={punto.id} value={punto.name}>
                                    {punto.name}
                                </option>
                            )}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Día</label>
                        <select className="form-control"
                            value={selectedDia}
                            onChange={e => setDia(e.target.value)}
                            disabled={!!newTurno}
                        >
                            <option value="" disabled>Selecciona un día</option>
                            {daysOfWeekDesktop.map(d =>
                                <option key={d} value={d}>{d}</option>
                            )}
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Horario</label>
                        <select className="form-control" value={selectedHorario} onChange={(e) => setHorario(e.target.value)}>
                            <option value="" disabled>Selecciona un horario</option>
                            {cartOrg.horarios.map((horario, index) =>
                                <option key={index} value={JSON.stringify(horario.start + '-' + horario.end)}>
                                    {horario.start}-{horario.end}
                                </option>
                            )}
                        </select>
                    </div>
                    <div className="mb-3">
                        {!!hermanosFiltrados.length ?
                            <>
                            <label className="form-label mt-2">
                                {' '}
                                <strong>Hermanos Disponibles:</strong>
                            </label>
                            <section className="d-flex justify-content-center align-items-center flex-column flex-wrap gap-4 ">
                                {hermanosFiltrados.map((hermano, index) =>
                                    <div key={index}
                                        className={`w-100 text-center card p-2 shadow cursor-pointer user-select-none c-pointer ${
                                            selectedParticipantes.includes(hermano) ? 'bg-success text-white' : ''
                                        }`}
                                        onClick={() => addRemoveParticipante(hermano)}
                                    >
                                        <strong>
                                            {hermano.name + ' ' + hermano.lastName + ' - ' + prioridades.find(p => p.valor === hermano.prioridad)?.etiqueta}
                                        </strong>
                                    </div>
                                )}
                            </section>
                            {hermanosFiltrados.length === 1 &&
                                <div className={'bg-warning text-white p-2 mt-2'}>
                                    Hay solo un hermano disponible para este Punto, Día y Horario
                                </div>
                            }
                            </>
                            :
                            <>
                            {selectedDia && selectedHorario && selectedMonth && selectedPunto &&
                                <div className={'text-white p-2'} style={{ backgroundColor: generalRed }}>
                                    No hay hermanos disponibles para este Punto, Día y Horario
                                </div>
                            }
                            </>
                        }
                    </div>
                </div>
                {!newTurno &&
                    <div className="col-md-6">
                        <div className="mb-3">
                            <div className="mb-3 text-center">
                                <h4>Sugerencias de Turnos</h4>
                            </div>
                            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                {suggestions.map((suggestion, index) =>
                                    <div key={index}
                                        className="card custom-card p-3 mb-2 hover"
                                        onClick={() => handleSuggestionClick(suggestion)}
                                    >
                                        <p>
                                            <strong>Punto:</strong> {suggestion.punto}
                                        </p>
                                        <p>
                                            <strong>Día:</strong> {suggestion.horario.dia}
                                        </p>
                                        <p>
                                            <strong>Horario:</strong> {suggestion.horario.horario}
                                        </p>
                                        <strong>Hermanos disponibles:</strong>{' '}
                                        <ul>
                                            {suggestion.hermanos.map((hermano: typeHermano) =>
                                                <li key={hermano.name + '_' + hermano.lastName}>
                                                    {hermano.name + ' ' + hermano.lastName}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="mb-3">
                <button className="btn btn-general-blue"
                    onClick={handleAddTurno}
                    disabled={!isValid}
                >
                    Agregar Turno
                </button>
                <button className="btn btn-secondary ms-2" onClick={closeModal}>
                    Cancelar
                </button>
            </div>
        </div>
    )
}
