import { deleteHTHObservationService, maskTheBlock } from '../../../services'
import { FC, useState } from 'react'
import { HTHItemCard, HTHObservationsForm } from '../'
import { setValuesAndOpenAlertModalReducer } from '../../../store'
import { typeObservation, typePolygon, typeTerritoryNumber } from '../../../models'
import { useDispatch } from 'react-redux'
import { useGetConfig, useGetCurrentUser } from '../../commons/custom-hooks'

type propsType = {
    closeShowAddFormHandler: () => void
    currentFace: typePolygon
    observation: typeObservation
    territoryNumber: typeTerritoryNumber
}

export const HTHObservationsItem: FC<propsType> = ({ closeShowAddFormHandler, currentFace, observation, territoryNumber }) => {
    const config = useGetConfig();
    const user = useGetCurrentUser();
    const dispatch = useDispatch()
    const [showForm, setShowForm] = useState(false)

    const editHandler = (): void => {
        closeShowAddFormHandler()
        setShowForm(true)
    }

    const deleteHandler = (): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: '¿Eliminar Observación?',
            message: `Se va a eliminar esta observación de la Manzana ${maskTheBlock(currentFace.block, config.usingLettersForBlocks)}, cara ${currentFace.street}: "${observation.text}"`,
            execution: () => {
                deleteHTHObservationService(territoryNumber, currentFace.block, currentFace.face, observation.id).then((success: boolean) => {
                    if (!success) {
                        dispatch(setValuesAndOpenAlertModalReducer({
                            mode: 'alert',
                            title: 'Algo falló',
                            message: 'Refrescar la página e intentar otra vez',
                            animation: 2
                        }))
                        return
                    }
                })
            }
        }))
    }

    const closeShowFormHandler = (): void => {
        setShowForm(false)
    }

    return (
        <>
            <HTHItemCard
                creatorId={observation.creatorId}
                date={observation.date}
                deleteHandler={deleteHandler}
                editHandler={editHandler}
                text={observation.text}
            />

            {/* edit observation form */}
            {(user.isAdmin || observation.creatorId === user.id) && showForm &&
                <HTHObservationsForm
                    closeShowFormHandler={closeShowFormHandler}
                    currentFace={currentFace}
                    territoryNumber={territoryNumber}
                    // specific
                    editText={observation.text}
                    idEdit={observation.id}
                />
            }
        </>
    )
}
