import { BsPeople, BsPinMap, BsCalendar, BsPen, BsCardList } from 'react-icons/bs';
import { CartAssignment } from '../cart/assignment/CartAssignment';
import { CartPoint } from '../cart/point/CartPoint';
import { getCartOrganizationService } from '../../services/cartServices1';
import { goToTop } from '../../services';
import { H2, Loading } from '../commons';
import { typeNewCartOrganization } from '../../models';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

type typeCarritosSection =
  | ''
  | 'participantes'
  | 'puntos'
  | 'calendario'
  | 'turnos'
  | 'update-disponibilidad'
  | 'autoregistro'
  | 'solicitudes';

export const Carts1Page = () => {
    const [cartOrg, setCartOrg] = useState<typeNewCartOrganization | null>(null);
    const [currentSection, setCurrentSection] = useState<typeCarritosSection>('');
    const [isLoading, setIsLoading] = useState(true);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        getCartOrganizationService().then(co => {
            setIsLoading(false);
            console.log(co);
            if (co) setCartOrg(co);
        });
    }, [refreshCounter]);

    useEffect(() => {
        const relativePath = window.location.pathname;
        let section: typeCarritosSection = '';
        if (relativePath === '/gestor-carritos1/participantes') section = 'participantes';
        if (relativePath === '/gestor-carritos1/puntos') section = 'puntos';
        if (relativePath === '/gestor-carritos1/calendario') section = 'calendario';
        if (relativePath === '/gestor-carritos1/turnos') section = 'turnos';
        if (relativePath === '/gestor-carritos1/update-disponibilidad') section = 'update-disponibilidad';
        if (relativePath === '/gestor-carritos1/autoregistro') section = 'autoregistro';
        if (relativePath === '/gestor-carritos1/solicitudes') section = 'solicitudes';
        setCurrentSection(section);
        goToTop();
    }, [window.location.pathname]);

    if (isLoading) return <Loading mt={'80px'} />;

    if (!cartOrg) return <div className={'mt-5'}>No se pudieron recuperar los datos</div>;

    return (
        <div>
            <H2 title={'CARRITOS'} mt={'50px'} />
            <section className="mb-4 px-4 mx-auto">
                {currentSection === '' &&
                    <div className="d-grid gap-3 m-auto mt-2" style={{ maxWidth: '600px' }}>
                        <button className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center mt-4"
                            onClick={() => navigate('puntos')}
                        >
                            <BsPinMap className="me-2" />
                            Puntos
                        </button>
                        <button className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center"
                            onClick={() => navigate('participantes')}
                            >
                            <BsPeople className="me-2" />
                            Participantes
                        </button>
                        <button className={'btn btn-size12 d-flex align-items-center justify-content-center ' + ((cartOrg.requests?.filter(x => x.status === 'pending').length ?? 0) > 0 ? 'btn-general-red' : 'btn-general-blue')} 
                            onClick={() => navigate('solicitudes')}
                        >
                            <BsCardList className="me-2" /> 
                            {(cartOrg.requests?.filter(x => x.status === 'pending').length ?? 0) > 0 ? `Solicitudes pendientes:  (${(cartOrg.requests?.filter(x => x.status === 'pending').length ?? 0)})`  : 'No hay solicitudes pendientes'}
                        </button>

                        <button className="btn btn-general-blue btn-size12 d-flex align-items-center justify-content-center"
                            onClick={() => navigate('calendario')}
                        >
                            <BsCalendar className="me-2" /> Calendario
                        </button>
                        <button className="btn btn-general-red btn-size12 d-flex align-items-center justify-content-center"
                            style={{ height: '65px' }}
                            onClick={() => navigate('turnos')}
                        >
                            <BsPen className="me-2" />
                            <h3 className="mb-0">Asignacion de turnos</h3>
                        </button>
                    </div>
                }
            </section>

            <div className="card shadow mx-auto">
                {currentSection === 'puntos' &&
                    <CartPoint
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
                {currentSection === 'turnos' &&
                    <CartAssignment
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                }
                {/* {currentSection === 'hermanos' &&
                    <HermanosCarritos
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                } */}
                {/* {currentSection === 'calendario' &&
                    <CalendarioCarritos
                        cartOrg={cartOrg}
                        nextMonth={false}
                        setRefreshCounter={setRefreshCounter}
                        thisMonth={false}
                    />
                } */}
                {/* no se estaría usando: */}
                {/* {currentSection === 'update-disponibilidad' &&
                    <HermanosCarritosUpdateDisponibilidad
                        cartOrg={cartOrg}
                        onSave={() => setCurrentSection('hermanos')}
                        setRefreshCounter={setRefreshCounter}
                    />
                } */}
                {/* {currentSection === 'autoregistro' &&
                    <HermanosCarritosAutoRegistro
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                } */}
                {/* {currentSection === 'solicitudes' &&
                    <SolicitudesCarritos
                        cartOrg={cartOrg}
                        setRefreshCounter={setRefreshCounter}
                    />
                } */}
            </div>
        </div>
    )
}
