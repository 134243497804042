import { getHTHBuildingService } from '../../services'
import { HTHBuildingModal } from '../house-to-house'
import { SERVER, socketIoEvents } from '../../app-config'
import { setValuesAndOpenAlertModalReducer } from '../../store'
import { Socket, io } from 'socket.io-client'
import { typeAddress, typeBlock, typeFace, typeHTHBuilding, typePolygon, typeTerritoryNumber } from '../../models'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useIdleTimeout } from '../commons/custom-hooks'
import { useNavigate, useParams } from 'react-router'

const socket: Socket = io(SERVER, { withCredentials: true })

export const HTHBuildingPage = () => {
    const block = useParams().block as typeBlock
    const congregation = parseInt(useParams().congregation || '')
    const face = useParams().face as typeFace
    const streetNumber = parseInt(useParams().streetNumber || '')
    const territoryNumber = useParams().territoryNumber as typeTerritoryNumber

    const [currentBuilding, setCurrentBuilding] = useState<typeHTHBuilding|null>(null)
    const [currentFace, setCurrentFace] = useState<typePolygon|null>(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeBuildingModalHandler = () => navigate('/')

    useIdleTimeout(5*60*1000, () => closeBuildingModalHandler())

    const refreshHTHTerritoryHandler = (): void => {
        if (!congregation || isNaN(congregation) || !territoryNumber || !block || !face || !streetNumber)
            return
        getHTHBuildingService(congregation, territoryNumber, block, face, streetNumber).then(response => {
            if (!response) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron recuperar los datos; tal vez no haya internet",
                    animation: 2
                }))
                return
            }
            if (response.notSharedToday) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "Este edificio no fue compartido hoy por un capitán de salida",
                    animation: 2
                }))
                return
            }
            const hthTerritory0 = response.hthTerritory
            const currentFace0: typePolygon|undefined = hthTerritory0?.map.polygons.find(x => x.block === block && x.face === face)
            const currentBuilding0: typeHTHBuilding|undefined = currentFace0?.buildings?.find(x => x.streetNumber === streetNumber)
            if (!hthTerritory0 || !currentFace0 || !currentBuilding0) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se encontró el edificio; tal vez no haya internet",
                    animation: 2
                }))
                return
            }
            setCurrentFace(currentFace0)
            setCurrentBuilding(currentBuilding0)
        })
    }

    useEffect(() => {
        refreshHTHTerritoryHandler()
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        socket.on(socketIoEvents.hthChange, (congregation0: number, territoryNumber0: typeTerritoryNumber, address?: typeAddress) => {
            if (!congregation0 || congregation0 !== congregation) return
            if (!territoryNumber0 || territoryNumber0 !== territoryNumber) return
            if (!address || (address.block !== block || address.face !== face || address.streetNumber !== streetNumber)) return
            console.log("Refrescando por uso de un usuario")
            setTimeout(() => {
                refreshHTHTerritoryHandler()
            }, Math.floor(Math.random() * 2000))
        })
        return () => { socket.off(socketIoEvents.hthChange) }
    // eslint-disable-next-line
    }, [block, congregation, face, streetNumber, territoryNumber])

    return (
        <>
            {currentBuilding && currentFace && !!territoryNumber &&
                <HTHBuildingModal
                    closeBuildingModalHandler={closeBuildingModalHandler}
                    congregation={congregation}
                    currentBuilding={currentBuilding}
                    currentFace={currentFace}
                    territoryNumber={territoryNumber}
                />
            }
        </>
    )
}
