import { Card, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { FC, useMemo, useState } from 'react'
import { getHTHFaceFromAddress, maskTheBlock, maskTheFace } from '../../services'
import { Loading } from '../commons'
import { typeHTHFace } from '../../models'
import { useGetConfig } from '../commons/custom-hooks'
import { useNavigate } from 'react-router'

type propsType = {
    closeGeolocationModalHandler: () => void
}

export const ManualLocationModal: FC<propsType> = ({ closeGeolocationModalHandler }) => {
    const config = useGetConfig();
    const [address, setAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [showNotFound, setShowNotFound] = useState(false)
    const [territory, setTerritory] = useState<typeHTHFace | null>(null);
    const navigate = useNavigate()

    const isValid: boolean = useMemo(() =>
        address.trim().length > 6
        && !!address.trim().replace(/[^0-9]/g, '').length  // at least 1 number
        && address.trim().includes(' ')
    , [address]);

    const sendAddress = async () => {
        if (!isValid) return;
        setLoading(true)
        const response = await getHTHFaceFromAddress(address.trim() + ' CABA')
        setLoading(false)
        if (response?.success && response.hthFace?.block && response.hthFace?.face && response.hthFace?.territoryNumber && response.hthFace.street) {
            setTerritory(response.hthFace)
        } else {
            setShowNotFound(true)
        }
    }

    return (<>
        <Modal
            fullscreen={'md-down'}
            onHide={closeGeolocationModalHandler}
            show={true}
            size={'lg'}
        >
            <Modal.Header closeButton>
                <Modal.Title> Ubicar territorio, manzana y cara </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel
                    className={'mb-3 text-dark'}
                    label={"Dirección"}
                >
                    <Form.Control
                        type={'text'}
                        className={'form-control'}
                        placeholder={''}
                        value={address}
                        onChange={e => {
                            setShowNotFound(false)
                            setTerritory(null);
                            setAddress((e.target as HTMLInputElement).value)
                        }}
                        onKeyDown={e => e.key === 'Enter' ? sendAddress() : null}
                        autoFocus
                    />
                </FloatingLabel>
                {loading ?
                    <Loading white={false} mt='40px' mb='20px' />
                    :
                    <button className='btn btn-general-blue w-100'
                        onClick={sendAddress}
                        disabled={!isValid || showNotFound || !!territory}
                    >
                        Aceptar
                    </button>
                }

                {territory &&
                    <Card className='mt-5'>
                        <Card.Header>
                            <h3>Respuesta</h3>
                        </Card.Header>
                        <Card.Body>
                            <div>
                                <button className='btn btn-general-red btn-lg'
                                    onClick={() => navigate(`/casa-en-casa/${territory.territoryNumber}?b=${territory.block}&f=${territory.face}`)}
                                >
                                    Territorio {territory.territoryNumber} - Manzana {maskTheBlock(territory.block, config.usingLettersForBlocks)} - Cara "{maskTheFace(territory.face, config.usingLettersForBlocks)}" {territory.street}
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                }

                {showNotFound &&
                    <h5 className={'bg-danger text-white text-center py-3 mt-4'}>No se encontró</h5>
                }
            </Modal.Body>
        </Modal>
    </>)
}
