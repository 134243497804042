import { BsCalendar, BsPen, BsPerson, BsShare, BsTrash } from 'react-icons/bs';
import { Card } from 'react-bootstrap';
import { deleteHermanoService } from '../../../services/cartServices';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { DOMAIN } from '../../../app-config';
import { HermanoDetalleModal } from '../carritos-common/HermanoDetalleModal';
import { HermanosCarritosFormAgregar } from './HermanosCarritosFormAgregar';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { prioridades, typeCartDetail, typeCartOrganization, typeHermano, typeSolicitud } from '../../../models';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import WhatsappShareButton from 'react-share/lib/WhatsappShareButton';

type propsType = {
    cartOrg: typeCartOrganization;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const HermanosCarritos: FC<propsType> = ({ cartOrg, setRefreshCounter }) => {
    const [hermanoToEdit, setHermanoToEdit] = useState<typeHermano | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedHermano, setSelectedHermano] = useState<typeHermano | null>(null);
    const [showFormAdd, setShowFormAdd] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const solicitud = location.state?.solicitud as typeSolicitud;

    const normalizeString = (str: string) => str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
    ;

    const filteredHermanos = cartOrg.hermanos?.filter(hermano =>
        normalizeString(hermano.name).includes(normalizeString(searchTerm)) ||
        normalizeString(hermano.lastName).includes(normalizeString(searchTerm)) ||
        hermano.phone.includes(searchTerm) ||
        hermano.disponibilidad.some(d =>
            normalizeString(d.punto).includes(normalizeString(searchTerm)) ||
            d.horarios.some((h) => normalizeString(h.dia).includes(normalizeString(searchTerm)))
        )
    )

    const handleShowModal = (hermano: typeHermano) => {
        setSelectedHermano(hermano);
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedHermano(null);
    }

    const toggleFormAdd = () => {
        setShowFormAdd(x => !x);
        if (showFormAdd) {
            setSelectedHermano(null);
        }
    }

    const handleSave = () => {
        setShowFormAdd(false);
        setHermanoToEdit(null);
    }

    const handleEdit = (hermano: typeHermano) => {
        setHermanoToEdit(hermano);
        setShowFormAdd(true);
    }

    const handleDelete = (hermano: typeHermano) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar el hermano ${hermano.name} ${hermano.lastName}. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteHermanoService(hermano);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    useEffect(() => {
        if (solicitud) {
            setShowFormAdd(true);
            let hermano: typeHermano = {
                user: solicitud.user,
                name: solicitud.name,
                lastName: solicitud.lastName,
                phone: '',
                participo: solicitud.participo,
                disponibilidad: solicitud.disponibilidad,
                prioridad: prioridades[0].valor,
            }
            setHermanoToEdit(hermano);
            toggleFormAdd();
        }
    }, []);

    return (
        <div className="container mt-4">
            {showModal && selectedHermano &&
                <HermanoDetalleModal
                    closeModal={handleCloseModal}
                    hermano={selectedHermano as typeCartDetail}
                />
            }
            <div className="d-flex align-items-center justify-content-between">
                <h3 className="mb-4 mt-2">
                    {showFormAdd ? hermanoToEdit ? 'Editar hermano' : 'Agregar hermano' : 'Listado de hermanos'} <BsPerson />
                </h3>
                <div className="d-flex gap-3">
                    {!showFormAdd &&
                        <WhatsappShareButton
                            className={'btn btn-general-blue mt-2 mb-4 px-3'}
                            url={`${DOMAIN}/acceso?url=/gestor-carritos/autoregistro`}
                            windowWidth={660}
                            windowHeight={460}
                        >
                            Compartir invitación {'  '}
                            <BsShare />
                        </WhatsappShareButton>
                    }
                    <button className={`btn btn-general-${showFormAdd ? 'red' : 'blue'} mb-4 mt-2`}
                        onClick={() => setShowFormAdd(!showFormAdd)}
                    >
                        {showFormAdd ? 'Cancelar' : 'Agregar hermano'}
                    </button>
                </div>
            </div>

            {!showFormAdd &&
                <div>
                    <input type="text"
                        className="form-control mb-4"
                        placeholder="Buscar por nombre, apellidos o teléfono..."
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoFocus
                    />
                    <div className="d-none d-md-block mb-5">
                        <table className="table table-bordered table-responsive shadow">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Teléfono</th>
                                    <th>Participó</th>
                                    <th>Prioridad</th>
                                    <th>Disponibilidad</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredHermanos?.map((hermano, index) =>
                                    <tr key={index}>
                                        <td>{hermano.name}</td>
                                        <td>{hermano.lastName}</td>
                                        <td>{hermano.phone}</td>
                                        <td>{hermano.participo ? 'Sí' : 'No'}</td>
                                        <td>{prioridades.find(p => p.valor === hermano.prioridad)?.etiqueta}</td>
                                        <td>
                                            <button className="btn btn-general-blue w-100" onClick={() => handleShowModal(hermano)}>
                                                <BsCalendar className='mb-1 me-2' /> Ver
                                            </button>
                                        </td>
                                        <td className="d-flex align-items-center justify-content-center gap-2">
                                            <button onClick={() => handleEdit(hermano)} className="btn btn-general-blue">
                                                <BsPen />
                                            </button>
                                            <button className="btn btn-general-red" onClick={() => handleDelete(hermano)}>
                                                <BsTrash />
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="d-md-none">
                        {filteredHermanos?.map((hermano, index) =>
                            <Card key={index} className="mb-3 custom-card" onClick={() => handleShowModal(hermano)}>
                                <Card.Body className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <Card.Title>
                                            {hermano.name} {hermano.lastName}
                                        </Card.Title>
                                    </div>
                                    <div className="d-flex gap-2">
                                        <button className={'btn btn-general-blue'}
                                            onClick={() => handleEdit(hermano)}
                                        >
                                            <BsPen />
                                        </button>
                                        <button className={'btn btn-general-red'}
                                            onClick={() => handleDelete(hermano)}
                                        >
                                            <BsTrash />
                                        </button>
                                    </div>
                                </Card.Body>
                            </Card>
                        )}
                    </div>
                </div>
            }

            {showFormAdd &&
                <HermanosCarritosFormAgregar
                    cartOrg={cartOrg}    
                    hermanoToEdit={hermanoToEdit || undefined}
                    onSave={handleSave}
                    setRefreshCounter={setRefreshCounter}
                    solicitud={solicitud}
                />
            }
        </div>
    )
}
