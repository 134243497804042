import { BsTrash } from 'react-icons/bs';
import { daysOfWeekDesktop } from '../../../app-config';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { typeDisponibilidad, typeDisponibilidadForm, typeCartOrganization, typePunto } from '../../../models';

type propsType = {
    cartOrg: typeCartOrganization;
    disponibilidad: typeDisponibilidad[];
    setDisponibilidad: Dispatch<SetStateAction<typeDisponibilidad[]>>;
}

export const DisponibilidadCarritosForm: FC<propsType> = ({ cartOrg, disponibilidad, setDisponibilidad }) => {
    const [allHorarios, setAllHorarios] = useState(false);
    const [selectedDia, setSelectedDia] = useState('');
    const [selectedHorario, setSelectedHorario] = useState('');
    const [selectedPunto, setSelectedPunto] = useState<typePunto | null>(null);

    const isValid: boolean = useMemo(() =>
        !!selectedPunto && !!selectedDia && (!!selectedHorario || allHorarios)
    , [selectedDia, selectedHorario, selectedPunto, allHorarios]);

    const handleAddDisponibilidad2 = (newDisponibilidadForm: typeDisponibilidadForm) => {
        setDisponibilidad(prevDisponibilidad => {
            const newDisponibilidad = [...prevDisponibilidad];
            const puntoIndex = newDisponibilidad.findIndex((d) => d.punto === newDisponibilidadForm.punto);
            if (puntoIndex !== -1) {
                const diaIndex = newDisponibilidad[puntoIndex].horarios.findIndex((h) => h.dia === newDisponibilidadForm.dia);
                if (diaIndex !== -1) {
                    if (!newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.includes(newDisponibilidadForm.horario)) {
                        newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.push(newDisponibilidadForm.horario);
                    } else {
                        alert('El horario ya existe para este día en este punto.');
                    }
                } else {
                    newDisponibilidad[puntoIndex].horarios.push({ dia: newDisponibilidadForm.dia, horarios: [newDisponibilidadForm.horario] });
                }
            } else {
                newDisponibilidad.push({
                    punto: newDisponibilidadForm.punto,
                    // puntoId: newDisponibilidadForm.puntoId,
                    horarios: [
                        { dia: newDisponibilidadForm.dia, horarios: [newDisponibilidadForm.horario] }
                    ]
                });
            }
            return newDisponibilidad;
        });
    }

    const handleAddDisponibilidad = () => {
        if (!isValid) return;
        if (allHorarios) {
            for (let i = 0; i < cartOrg.horarios.length; i++) {
                const horarioObj = cartOrg.horarios[i];
                const newDisponibilidad: typeDisponibilidadForm = {
                    punto: selectedPunto!.name,
                    // puntoId: Date.now().toString(),
                    dia: selectedDia,
                    horario: `${horarioObj.start}-${horarioObj.end}`
                }
                handleAddDisponibilidad2(newDisponibilidad);
            }
        } else {
            const newDisponibilidad: typeDisponibilidadForm = {
                punto: selectedPunto!.name,
                // puntoId: Date.now().toString(),
                dia: selectedDia,
                horario: selectedHorario
            }
            handleAddDisponibilidad2(newDisponibilidad);
        }
        setSelectedPunto(null);
        setSelectedDia('');
        setSelectedHorario('');
        setAllHorarios(false);
    }

    const onRemoveDisponibilidad = (puntoIndex: number, diaIndex: number, horarioIndex: number) => {
        setDisponibilidad((prevDisponibilidad) => {
            const newDisponibilidad = [...prevDisponibilidad];
            newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.splice(horarioIndex, 1);
            if (newDisponibilidad[puntoIndex].horarios[diaIndex].horarios.length === 0) {
                newDisponibilidad[puntoIndex].horarios.splice(diaIndex, 1);
            }
            if (newDisponibilidad[puntoIndex].horarios.length === 0) {
                newDisponibilidad.splice(puntoIndex, 1);
            }
            return newDisponibilidad;
        });
    }

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="mb-2">
                    <h5 className={'my-3'}>Agregar disponibilidad:</h5>
                    <label className="form-label">Seleccionar Punto:</label>
                    <div className="d-flex flex-wrap gap-1">
                        {cartOrg.puntos.map(punto =>
                            <button key={punto.id}
                                type="button"
                                className={`btn btn-outline-general-blue me-2 ${selectedPunto?.id === punto.id ? 'btn-general-blue' : ''}`}
                                onClick={() => setSelectedPunto(punto)}
                            >
                                {punto.name}
                            </button>
                        )}
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label">Seleccionar Día:</label>
                    <div className="d-flex flex-wrap gap-1">
                        {daysOfWeekDesktop.map((dia, index) => (
                        <button
                            key={index}
                            type="button"
                            className={`btn btn-outline-general-blue me-2 ${selectedDia === dia ? 'btn-general-blue' : ''}`}
                            onClick={() => setSelectedDia(dia)}>
                            {dia}
                        </button>
                        ))}
                    </div>
                </div>

                <div className="mb-3">
                    <label className="form-label">Seleccionar Horario:</label>
                    <div className="d-flex flex-wrap gap-1">
                        <button type="button"
                            className={`btn btn-outline-general-blue ${allHorarios ? 'btn-general-blue' : ''}`}
                            onClick={() => {
                                setSelectedHorario('');
                                setAllHorarios(true);
                            }}
                        >
                            Todos
                        </button>
                        {cartOrg.horarios.map((horario, index) =>
                            <button key={index}
                                type="button"
                                className={`btn btn-outline-general-blue me-2 ${
                                    selectedHorario === horario.start + '-' + horario.end ? 'btn-general-blue' : ''
                                }`}
                                onClick={() => {
                                    setAllHorarios(false);
                                    setSelectedHorario(horario.start + '-' + horario.end);
                                }}
                            >
                                {horario.start + '-' + horario.end}
                            </button>
                        )}
                    </div>
                </div>

                <div className="d-flex flex-column gap-2 w-100 px-4 mt-4">
                    <button
                        type="button"
                        className="btn btn-general-blue mb-3 text-center"
                        onClick={handleAddDisponibilidad}
                        disabled={!isValid}
                    >
                        Agregar Disponibilidad
                    </button>
                </div>
            </div>

            <div className="col-md-6">
                <h5 className={'my-3'}>Disponibilidad:</h5>
                <div className="d-flex flex-column gap-2 w-100 px-4" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {!disponibilidad.length &&
                        <div className="text-center card shadow rounded p-4">
                            Selecciona punto, dia y horario, para agregar disponibilidad
                        </div>
                    }
                    {disponibilidad?.map((disp, puntoIndex) =>
                        <li className="border p-2 rounded shadow list-group-item" key={puntoIndex}>
                            <h5 className="text-center">{disp.punto}</h5>
                            <ul>
                                {disp.horarios?.map((hor, diaIndex) =>
                                    <li key={diaIndex}>
                                        {hor.dia}:
                                        <article className="d-flex flex-wrap flex-column gap-2 p-2">
                                            {hor.horarios?.map((horario, horarioIndex) =>
                                                <div key={horarioIndex}
                                                    className="d-flex justify-content-between align-items-center bg-light border rounded p-2"
                                                >
                                                    {horario}
                                                    <div>
                                                        <button type="button"
                                                            className="btn btn-general-red btn-sm m-auto"
                                                            onClick={() => onRemoveDisponibilidad(puntoIndex, diaIndex, horarioIndex)}
                                                        >
                                                            Eliminar {''}
                                                            <BsTrash />
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </article>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                </div>
            </div>
        </div>
    )
}
