import { CalendarioCarritos } from '../carritos/calendario/CalendarioCarritos';
import { Document, Page } from 'react-pdf';
import { FaArrowLeft, FaArrowRight, FaDownload } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import { getCartOrganizationService } from '../../services/cartServices';
import { Hr, Loading } from '../commons';
import { typeBoardItem, typeCartOrganization, typeItemBoardFile } from '../../models';
import { useGetIsMobile } from '../commons/custom-hooks';

type propsType = {
    item: typeBoardItem;
}

export const BoardItemDisplay: FC<propsType> = ({ item }) => {
    const [cartOrg, setCartOrg] = useState<typeCartOrganization | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (item.title !== 'Carritos') {
            setCartOrg(null);
            return ;
        }
        setIsLoading(true);
        getCartOrganizationService().then(co => {
            setIsLoading(false);
            setCartOrg(co);
        });
    }, [item.title]);

    return (
        <>
            <style>{`.react-pdf__Page__canvas {width: 100% !important; height: auto !important;}`}</style>
            {isLoading && <Loading mt={'40px'} />}
            {cartOrg && <>
                <CalendarioCarritos
                    cartOrg={cartOrg}
                    nextMonth={false}
                    setRefreshCounter={() => {}}
                    thisMonth={true}
                />
                <CalendarioCarritos
                    cartOrg={cartOrg}
                    nextMonth={true}
                    setRefreshCounter={() => {}}
                    thisMonth={false}
                />
            </>}
            <div>
                {!!item.files?.length && item.files.map(file =>
                    <File key={file.date}
                        file={file}
                    />
                )}
            </div>
        </>
    )
}

const File = ({ file }: { file: typeItemBoardFile }) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const isMobile = useGetIsMobile();

    return (
        <div key={file.date} className={'w-100 h-auto overflow-hidden mt-5'} style={{ backgroundColor: 'lightgrey' }}>
            <Hr />
            {!!numPages &&
                <div className={'d-flex justify-content-between align-items-center my-2 mx-5'}>
                    <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''}`}
                        style={{ width: isMobile ? '60px' : undefined }}
                        onClick={() => setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1))}
                        disabled={pageNumber <= 1}
                    >
                        <FaArrowLeft /> {isMobile ? '' : 'Anterior'}
                    </button>
                    <span className={isMobile ? '' : 'ms-1'}>
                        Página {pageNumber} de {numPages}
                    </span>
                    <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''}`}
                        style={{ width: isMobile ? '60px' : undefined }}
                        onClick={() => setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages))}
                        disabled={pageNumber >= numPages}
                    >
                        {isMobile ? '' : 'Siguiente'} <FaArrowRight />
                    </button>
                </div>
            }
            <div className={'text-center my-2'}>
                <a href={URL.createObjectURL(new Blob([new Uint8Array(file.data)]))}
                    className={`btn btn-general-red btn-sm ${isMobile ? '' : 'py-2'} px-3`}
                    download={file.originalName}
                >
                    <FaDownload className={'mb-1 me-2'} /> Descargar
                </a>
            </div>
            <div className={'m-3'}>
                <Document
                    // file={{ data: new Uint8Array(file.data) }}
                    file={{ url: URL.createObjectURL(new Blob([new Uint8Array(file.data)], { type: 'application/pdf' })) }}
                    loading={<Loading mt={'90px'} mb={'50px'} />}
                    onLoadSuccess={e => setNumPages(e.numPages)}
                    
                >
                    <Page className={'w-100 h-auto'} pageNumber={pageNumber} scale={1.5} />
                </Document>
            </div>
        </div>
    )
}
