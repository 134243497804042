import { BsTrash } from 'react-icons/bs';
import { deleteTurnoService } from '../../../services/cartServices';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { TurnosCarritosAgregar } from '../carritos-common/TurnosCarritosAgregar';
import { typeCartOrganization, typeTurno } from '../../../models';
import { useDispatch } from 'react-redux';
import { useGetIsMobile } from '../../commons/custom-hooks';

type propsType = {
    cartOrg: typeCartOrganization;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const TurnosCarritos: FC<propsType> = ({ cartOrg, setRefreshCounter }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showFormAdd, setShowFormAdd] = useState(false);
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();

    const getMonthName = (mes: string) => {
        const [month, year] = mes.split('-');
        const date = new Date(Number(year), Number(month) - 1); // Ajusta el mes restando 1
        return capitalizeFirstLetter(date.toLocaleString('es-ES', { month: 'long', year: 'numeric' }));
    }

    const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

    const filteredTurnos = cartOrg.turnos.filter(turno => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        const monthMatch = turno.mes.toLowerCase().includes(lowerSearchTerm);
        const monthStringMatch = getMonthName(turno.mes)?.toLowerCase().includes(lowerSearchTerm);
        const puntoMatch = turno.punto.toLowerCase().includes(lowerSearchTerm);
        const diaMatch = turno.horario.dia.toLowerCase().includes(lowerSearchTerm);
        const hermanoMatch = turno.hermanos.some((hermano) =>
            `${hermano.name.toLowerCase()} ${hermano.lastName.toLowerCase()}`.includes(lowerSearchTerm)
        );
        return puntoMatch || diaMatch || hermanoMatch || monthMatch || monthStringMatch;
    });

    const handleDeleteTurno = (turno: typeTurno) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar este turno de ${turno.hermanos[0].name} ${turno.hermanos[0].lastName} y ${turno.hermanos[1].name} ${turno.hermanos[1].lastName} en ${turno.punto}. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deleteTurnoService(turno);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    return (
        <div className="container mt-3" style={{ maxWidth: '650px' }}>
            <div className="d-flex justify-content-between align-items-center my-4">
                <h2 className="text-center flex-grow-1">
                    {!showFormAdd ? <>{isMobile ? 'Turnos' : 'Listado de turnos'} - <span className={'small'}>Viendo {filteredTurnos.length}</span></> : 'Agregar turno'}
                </h2>
                <button className={`btn btn-general-${showFormAdd ? 'red' : 'blue'} ${isMobile ? 'btn-sm' : ''}`}
                    onClick={() => setShowFormAdd(!showFormAdd)}
                >
                    {showFormAdd ? 'Cancelar' : 'Agregar turno'}
                </button>
            </div>
            {!showFormAdd &&
                <div>
                    <input type="text"
                        className="form-control mb-4"
                        placeholder="Buscar por punto, día o hermano..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        autoFocus
                    />
                </div>
            }
            {!showFormAdd &&
                <div className="row">
                    {filteredTurnos?.map((turno, index) =>
                        <div key={index} className="col-12 mb-5">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title text-center">{turno.punto}</h5>
                                    <p className="card-text">
                                        Mes: <strong>{getMonthName(turno.mes)}</strong>
                                    </p>
                                    <p className="card-text">Dia: {turno.horario.dia}</p>
                                    <p className="card-text">Horario: {turno.horario.horario.replaceAll('"', '')}</p>
                                    <div className="card-text ">
                                        Hermanos:
                                        <ul>
                                            {turno.hermanos?.map((hermano, index) => (
                                            <li key={index}>
                                                {hermano.name} {hermano.lastName}
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="card-footer d-flex gap-4 justify-content-end">
                                    {/* <button className="btn btn-general-blue" onClick={() => handleEdit(turno)}>
                                        Editar <BsPen />
                                    </button> */}
                                    <button className="btn btn-general-red" onClick={() => handleDeleteTurno(turno)}>
                                        Eliminar <BsTrash />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }

            {showFormAdd &&
                <TurnosCarritosAgregar
                    cartOrg={cartOrg}
                    closeModal={() => setShowFormAdd(false)}
                    newTurno={null}
                    setRefreshCounter={setRefreshCounter}
                />
            }
        </div>
    )
}
