import { goToTop } from '../../services';
import { HouseToHouseSelector, TelephonicSelector } from '../selector';
import { Hr } from '../commons';
import { useEffect } from 'react';
import { useGetCurrentUser } from '../commons/custom-hooks';

export const SelectorPage = () => {
    const user = useGetCurrentUser();
    
    useEffect(() => {
        goToTop();
    }, []);

    return (
        <>
            {(user.isBuildingsAdmin || !!user.hthAssignments?.length) &&
                <>
                    <HouseToHouseSelector />

                    <Hr />
                    <Hr />
                </>
            }

            <TelephonicSelector />

            {/* <Hr />
            <Hr />

            <CampaignSelector /> */}
        </>
    )
}
