import { addCartEnabledPeriodService } from '../../../services/cartServices1';
import { getTimeFromPeriodData } from '../../../services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { typeNewPeriod, typeNewPoint } from '../../../models';
import { useDispatch } from 'react-redux';
import { useState, FC, Dispatch, SetStateAction, FormEvent, useMemo } from 'react';
import Select from 'react-select';

type propsType = {
    closeForm: () => void;
    point: typeNewPoint;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartPointEnabledPeriodAdd: FC<propsType> = ({ closeForm, point, setRefreshCounter }) => {
    const [endHour, setEndHour] = useState(6);
    const [endMinutes, setEndMinutes] = useState(0);
    const [startHour, setStartHour] = useState(6);
    const [startMinutes, setStartMinutes] = useState(0);
    const dispatch = useDispatch();

    const isValid: boolean = useMemo(() =>
        (startHour < endHour || (startHour === endHour && startMinutes < endMinutes))
        && !point.enabledPeriods.some(p => {
            const existingStart = p.startHour * 60 + p.startMinutes;
            const existingEnd = p.endHour * 60 + p.endMinutes;
            const newStart = startHour * 60 + startMinutes;
            const newEnd = endHour * 60 + endMinutes;
            return (
                (newStart < existingEnd && newEnd > existingStart)
            );
        })
    , [endHour, endMinutes, point.enabledPeriods, startHour, startMinutes]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!isValid) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a agregar el horario ${getTimeFromPeriodData(startHour, startMinutes, endHour, endMinutes)} hs. al punto '${point.name}'`,
            execution: async () => {
                const newPeriod: typeNewPeriod = {
                    startHour,
                    startMinutes,
                    endHour,
                    endMinutes,
                    id: ''
                };
                dispatch(showLoadingModalReducer());
                const success = await addCartEnabledPeriodService(point, newPeriod);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    closeForm();
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo agregar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    return (
        <form onSubmit={handleSubmit} className={'mt-3'}>
            <div className={'mb-3'}>
                <label className={'form-label'}>
                    Hora de inicio
                </label>
                <div className={'d-flex justify-content-start'}>
                    <Select className={'me-2'}
                        options={Array.from({ length: 18 }, (_, i) => ({ hour: i + 6 }))}
                        getOptionLabel={h => h.hour.toString().padStart(2, '0')}
                        getOptionValue={h => h.hour.toString()}
                        value={{ hour: startHour }}
                        onChange={h => setStartHour(h?.hour ?? 0)}
                    />
                    <Select
                        options={Array.from({ length: 12 }, (_, i) => ({ minutes: i * 5 }))}
                        getOptionLabel={m => m.minutes.toString().padStart(2, '0')}
                        getOptionValue={m => m.minutes.toString()}
                        value={{ minutes: startMinutes }}
                        onChange={m => setStartMinutes(m?.minutes ?? 0)}
                    />
                </div>
            </div>
            <div className={'mb-3'}>
                <label className={'form-label'}>
                    Hora de fin
                </label>
                <div className={'d-flex justify-content-start'}>
                    <Select className={'me-2'}
                        options={Array.from({ length: 18 }, (_, i) => ({ hour: i + 6 }))}
                        getOptionLabel={h => h.hour.toString().padStart(2, '0')}
                        getOptionValue={h => h.hour.toString()}
                        value={{ hour: endHour }}
                        onChange={h => setEndHour(h?.hour ?? 0)}
                    />
                    <Select
                        options={Array.from({ length: 12 }, (_, i) => ({ minutes: i * 5 }))}
                        getOptionLabel={m => m.minutes.toString().padStart(2, '0')}
                        getOptionValue={m => m.minutes.toString()}
                        value={{ minutes: endMinutes }}
                        onChange={m => setEndMinutes(m?.minutes ?? 0)}
                    />
                </div>
            </div>
            <div className={'d-flex justify-content-center mb-4 mt-4 gap-2'}>
                <button type={'submit'}
                    className={'btn btn-general-blue'}
                    disabled={!isValid}
                >
                    Agregar Horario
                </button>
                <button type={'submit'}
                    className={'btn btn-general-red'}
                    onClick={closeForm}
                >
                    Cancelar
                </button>
            </div>
        </form>
    )
}
