import { AlertModal, DarkModeButton, FloatingWidgets, Footer, LoadingModal, NavBar } from './commons';
import { appName, breakingPoint } from '../app-config';
import { changeMobileModeReducer, logoutReducer, refreshUserReducer, setConfigurationReducer } from '../store';
import { getUserByTokenService } from '../services';
import { MainRouter } from './routers/MainRouter';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAlertModal, useGetIsDarkMode, useGetIsMobile, useGetShowingLoadingModal } from './commons/custom-hooks';
import { useLocation, useNavigate } from 'react-router-dom';

export const App = () => {
    const [refresh, setRefresh] = useState(false);
    const alertModal = useGetAlertModal();
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const location = useLocation();
    const navigate = useNavigate();
    const showingLoadingModal = useGetShowingLoadingModal();

    useEffect(() => {
        window.addEventListener('resize', (event: any) => {
            const width: number = event.target.screen.width;
            if ((isMobile && width >= breakingPoint) || (!isMobile && width < breakingPoint)) {
                dispatch(changeMobileModeReducer({ isMobile: width < breakingPoint }));
            }
        })
    }, [dispatch, isMobile]);

    useEffect(() => {
        getUserByTokenService().then(response => {
            if (!response.user || !response.config) {
                if (response.user === false)
                    dispatch(logoutReducer());
                if (location.pathname === '/selector') {
                    navigate('/acceso');
                }
                return;
            }
            dispatch(refreshUserReducer(response.user));
            dispatch(setConfigurationReducer(response.config));
        });
    }, [refresh]);

    useEffect(() => {
        if (location.pathname === '/selector') {
            setRefresh(x => !x);
        }
    }, [location.pathname]);

    useEffect(() => {
        document.title = appName;
    }, []);

    return (
        <Suspense fallback={(<div> Cargando... </div>)}>

            <div style={{ backgroundColor: isDarkMode ? 'black' : 'white' }}>

                <div style={{ position: 'fixed', width: '100%', zIndex: 4 }}>
                    <NavBar />
                    <FloatingWidgets />
                    {alertModal.showingAlertModal && <AlertModal />}
                    {showingLoadingModal && <LoadingModal />}
                </div>

                <div style={{
                    maxWidth: isMobile ? '95%' : '90%',
                    paddingTop: '75px',
                    margin: 'auto',
                    minHeight: 'calc(100vh - 80px)'
                }}>
                    <MainRouter />
                    <DarkModeButton />
                </div>

                <Footer />

            </div>
        </Suspense>
    )
}
