import { getHTHTerritoriesForStatisticsService, getUsersService } from '../../services';
import { H2, Loading } from '../commons';
import { setValuesAndOpenAlertModalReducer } from '../../store';
import { StatisticsHTHAssignments } from './hth-statistics-subomp/StatisticsHTHAssignments';
import { StatisticsHTHBuildings } from './hth-statistics-subomp/StatisticsHTHBuildings';
import { StatisticsHTHMap } from './hth-statistics-subomp/StatisticsHTHMap';
import { typeHTHTerritory, typeUser } from '../../models';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetIsDarkMode } from '../commons/custom-hooks';

export const StatisticsHTH = () => {
    const [hthTerritories, setHthTerritories] = useState<typeHTHTerritory[]|null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showOption, setShowOption] = useState<0 | 1 | 2 | 3>(0);
    const [users, setUsers] = useState<typeUser[] | null>(null);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();

    useEffect(() => {
        getUsersService().then(us => {
            setUsers(us);
        });
        getHTHTerritoriesForStatisticsService().then(territories => {
            setIsLoading(false);
            if (!territories) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron obtener los territorios de Casa en Casa. Refrescar. Ver si hay internet.",
                    animation: 2
                }));
                return;
            }
            setHthTerritories(territories);
        });
    }, [dispatch]);

    if (isLoading) return <Loading mt={'50px'} />;

    return (
        <div className={'container'}>
            <H2 title={'ESTADÍSTICAS DE CASA EN CASA'} mb={'40px'} />
            {!!hthTerritories?.length ?
                <div>
                    <button className={`btn btn-${showOption === 1 ? 'general-blue' : 'general-red'} btn-size12 d-block mx-auto mt-4 mb-0`}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 1 ? 0 : 1)}
                    >
                        {showOption === 1 ? 'Viendo Asignaciones' : 'Ver Asignaciones'}
                    </button>
                    <button className={`btn btn-${showOption === 2 ? 'general-blue' : 'general-red'} btn-size12 d-block mx-auto mt-2 mb-0`}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 2 ? 0 : 2)}
                    >
                        {showOption === 2 ? 'Viendo Edificios' : 'Ver Edificios'}
                    </button>
                    <button className={`btn btn-${showOption === 3 ? 'general-blue' : 'general-red'} btn-size12 d-block mx-auto mt-2 mb-0`}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 3 ? 0 : 3)}
                    >
                        {showOption === 3 ? 'Viendo Predicación de Edificios' : 'Ver Predicación de Edificios'}
                    </button>
                    {showOption === 1 &&
                        <StatisticsHTHAssignments
                            hthTerritories={hthTerritories}
                            users={users}
                        />
                    }
                    {showOption === 2 &&
                        <StatisticsHTHBuildings
                            hthTerritories={hthTerritories}
                        />
                    }
                    {showOption === 3 &&
                        <StatisticsHTHMap
                            hthTerritories={hthTerritories}
                        />
                    }
                </div>
                :
                <h4 className={`text-center mt-5 ${isDarkMode ? 'text-white' : ''}`}>
                    No hay datos
                </h4>
            }
        </div>
    )
}
