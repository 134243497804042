import { BsMap, BsPen, BsTrash } from 'react-icons/bs';
import { deletePuntoService } from '../../../services/cartServices';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from '../../../store';
import { PuntosCarritosAgregar } from './PuntosCarritosAgregar';
import { typeCartOrganization, typePunto } from '../../../models';
import { useDispatch } from 'react-redux';

type propsType = {
    cartOrg: typeCartOrganization;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const PuntosCarritos: FC<propsType> = ({ cartOrg, setRefreshCounter }) => {
    const [puntoToEdit, setPuntoToEdit] = useState<typePunto | null>(null);
    const [showFormAdd, setShowFormAdd] = useState(false);
    const dispatch = useDispatch();

    const toggleFormAdd = () => {
        setShowFormAdd(x => !x);
        setPuntoToEdit(null);
    }

    const handleEdit = (index: number) => {
        setPuntoToEdit(cartOrg.puntos[index]);
        setShowFormAdd(true);
    }

    const handleSave = () => {
        setShowFormAdd(false);
        setPuntoToEdit(null);
    }

    const handleDelete = (punto: typePunto) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar el punto '${punto.name}'. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await deletePuntoService(punto);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    return (
        <div className="container mt-3" style={{ maxWidth: '850px' }}>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="text-center flex-grow-1">
                    {!showFormAdd ? 'Listado de puntos' : puntoToEdit ? 'Editar punto' : 'Agregar punto'} <BsMap />
                </h3>
                <button className={`btn btn-general-${showFormAdd ? 'red' : 'blue'}`} onClick={toggleFormAdd}>
                    {showFormAdd ? 'Cancelar' : 'Agregar punto'}
                </button>
            </div>

            {!showFormAdd &&
                <div className="row">
                    {cartOrg.puntos.map((punto, index) =>
                        <div key={index} className="col-12 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">{punto.name}</h5>
                                    <p className="card-text">Dirección: {punto.address}</p>
                                    <p className="card-text">Punto de retiro: {punto.pickupCartPoint}</p>
                                </div>
                                <div className="card-footer d-flex gap-4 justify-content-end">
                                    <button className="btn btn-general-blue" onClick={() => handleEdit(index)}>
                                        Editar <BsPen />
                                    </button>
                                    <button className="btn btn-general-red" onClick={() => handleDelete(punto)}>
                                        Eliminar <BsTrash />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }

            {showFormAdd &&
                <PuntosCarritosAgregar
                    onCancel={toggleFormAdd}
                    onSave={handleSave}
                    puntoToEdit={puntoToEdit || undefined}
                    setRefreshCounter={setRefreshCounter}
                />
            }
        </div>
    )
}
