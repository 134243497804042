import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getConfigFromLSService, setConfigToLSService } from '../services/localStorageServices';
import { typeConfig, unauthenticatedConfig } from '../models';

const configLocalStorage = getConfigFromLSService ? getConfigFromLSService() : alert("No se cargó Config LS Service");

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState: configLocalStorage ?? unauthenticatedConfig,
    reducers: {
        setConfigurationReducer: (state, action: PayloadAction<typeConfig>) => {
            if (Number.isInteger(action.payload.numberOfTerritories)) {
                state = action.payload;
                setConfigToLSService(action.payload);
            }
            return state;
        }
    }
});

export const { setConfigurationReducer } = configurationSlice.actions;
